import React, { Component } from 'react';
import styled from 'styled-components';
import { set, format } from 'date-fns';

const Wrapper = styled.div`
    margin: 15px 0;
`;

export default class MediaLength extends Component {
    state = {
        seconds: 0
    };

    componentWillUnmount() {
        this.stop();
    }

    start = () => {
        this.interval = setInterval(
            () => this.setState(prev => ({ seconds: prev.seconds + 1 })),
            1000
        );
    }

    stop = () => {
        clearInterval(this.interval);
    }

    reset = () => this.setState({ seconds: 0 });

    componentDidUpdate(prev) {
        !prev.recording && this.props.recording && this.start();
        prev.recording && !this.props.recording && this.stop();
        prev.data && !this.props.data && this.reset();
    }

    render() {
        const time = format(set(new Date(), { hours: 0, minutes: 0, seconds: this.state.seconds }), 'HH:mm:ss');

        return <Wrapper>
            { time }
        </Wrapper>;
    }
}

import React from 'react';
import styled from 'styled-components';
import { drop, prop } from 'ramda';
import { Link, withRouter } from 'react-router-dom';
import cx from 'classnames';

import ButtonLink from './ButtonLink';

export const StyledButtonContainer = styled.span`
    ${({ ancor }) => ancor ? `
        margin-bottom: 5px;
    ` : ''}

    a, button {
        background-color: ${prop('btnBackground')};
        color: ${prop('btnTextColor')};

        ${({ ancor }) => ancor ? `
            vertical-align: top;
        ` : ''}

        &:hover {
            background-color: ${prop('btnHoverBackground')};
            color: ${({ btnHoverTextColor, btnTextColor }) => btnHoverTextColor || btnTextColor};
        }
    }
`;

const StyledButton = props =>
    <StyledButtonContainer {...props.settings} ancor={props.ancor}>
        { props.formUrl ?
            <Link to={{
                pathname: props.formUrl,
                state: props.state,
                search: `${props.search || ''}${props.location.search ? (props.search ? `&${drop(1, props.location.search)}` : props.location.search) : ''}`,
            }} className={cx('btn', props.className)}>{ props.settings.btnText }</Link> :
            <ButtonLink className={cx('btn', props.className)}>
                { props.settings.btnText }
            </ButtonLink>
        }
    </StyledButtonContainer>
;

export default withRouter(StyledButton);

import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { append, head, assocPath, dissocPath, equals, pathOr, trim, drop } from 'ramda';
import { Flex, Box } from '@rebass/grid';
import { Modal } from 'antd';
import { Link, withRouter } from 'react-router-dom';

import withSettings from '../../hocs/withSettings';
import Block from '../../Block';
import DataContext from '../../../context/DataContext';
import EditContext from '../../../context/EditContext';
import { DEFAULT_LIME_VACANCY } from '../../../constants/templates/lime';
import VacancyDescription from './VacancyDescription';
import {
    Button,
    Card,
    TitleCard,
    ControlsCard,
    VacanciesContainer,
    RemoveSmallVacancyButton,
    AddVacancyButton,
    RemoveImageButton,
    ListCard,
    BorderCard,
    VacancyButton,
    UploadImageWrapper,
    VacancyControlButtons,
    VacancyBlockControlButtons,
    VacancyBlock,
    SmallVacancyBlock,
    VacancyLinkButtonWrapper
} from './styledComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt, faImage } from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
    display: ${({ blockText }) => blockText ? 'flex' : 'block'};
    flex-wrap: wrap;
    max-width: 1020px;
    margin: 0 auto;
`;
const BoxVacanciesDescription = styled(Box)`
    color: #fff;
    @media(max-width: 52em){
        .ql-editor .ql-align-right{
            text-align: center;
        }
    }
`;

const VacancyLinkButton = withSettings(() => null, 'modal');

const getVacancyString = html => trim(html.replace(/<[^]*?>/g, ''));

class Vacancies extends Component {
    state = {
        openedModal: null,
        hover: {}
    };

    openedModal = (row, item) => this.setState({ openedModal: { row, item }});

    closeModal = () => this.setState({ openedModal: null })

    removeVacancy = (onChange, row, index) => {
        const vacancies = dissocPath([row, index], this.props.data.vacancies.data);

        onChange('vacancies.data', vacancies);
    }

    addVacancy = (onChange, row) => {
        const { data } = this.props.data.vacancies;
        const vacancies = append(DEFAULT_LIME_VACANCY, data[row]);

        onChange('vacancies.data', assocPath([row], vacancies, data));
    }

    addVacancyRow = onChange => {
        const vacancies = append([DEFAULT_LIME_VACANCY], this.props.data.vacancies.data);

        onChange('vacancies.data', vacancies);
    }

    uploadImage = (e, onChange, row, index) => {
        const file = head(e.target.files);

        if (file) {
            const filereader = new FileReader();

            filereader.readAsDataURL(file);
            filereader.onload = () => onChange(
                'vacancies.data',
                assocPath([row, index, 'image'], filereader.result, this.props.data.vacancies.data)
            );
        }
    }

    removeImage = (onChange, row, index) => {
        onChange(
            'vacancies.data',
            dissocPath([row, index, 'image'], this.props.data.vacancies.data)
        );
    }

    removeVacancyBlock = (onChange, row) => {
        onChange(
            'vacancies.data',
            dissocPath([row], this.props.data.vacancies.data)
        );
    }

    isOpenedModal = modal => {
        return equals(modal, this.state.openedModal)
    };

    renderBlockControlButtons = (row, index) => {
        return <DataContext.Consumer>
            { ({ onChange }) =>
                <Fragment>
                    { row.length < 3 &&
                        <VacancyButton
                            className='btn-hidden-vacancy'
                            onClick={() => this.addVacancy(onChange, index)}>
                            <FontAwesomeIcon icon={faPlus} />
                        </VacancyButton>
                    }
                    { this.props.data.vacancies.data.length > 1 &&
                        <VacancyButton
                            className='btn-hidden-vacancy'
                            onMouseEnter={() => this.enter({ row: index })}
                            onMouseLeave={this.leave}
                            onClick={() => this.removeVacancyBlock(onChange, index)}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </VacancyButton>
                    }
                </Fragment>
            }
        </DataContext.Consumer>;
    }

    enter = hover => this.setState({ hover });

    leave = () => this.setState({ hover: {} });

    isHover = (row, item) => {
        const { hover } = this.state;

        return hover.row === row && hover.item === item;
    }

    noVacancies = () => {
        return !pathOr([], ['params', 'vacancies'], this.props).length;
    }

    renderBlockVacancies = edit => {
        const { settings, data, formUrl, allSettings, location: { search }} = this.props;

        return <Fragment>
            <div className='vacancies-items'>
                { data.vacancies.data.map((row, index) =>
                    <VacancyBlock
                        key={`vacancy-row-${index}`}
                        flexWrap='wrap'
                        hover={this.isHover(index)}>
                        { edit &&
                            <VacancyBlockControlButtons block>
                                { this.renderBlockControlButtons(row, index) }
                            </VacancyBlockControlButtons>
                        }
                        { row.map((item, i) =>
                            <Card
                                key={`vacancy-${index}-${i}`}
                                hover={this.isHover(index, i)}
                                {...settings}
                                image={item.image}
                                width={[1, 1, 1/row.length, 1/row.length]}
                                px={25}
                                py={20}>
                                { edit &&
                                    <DataContext.Consumer>
                                        { ({ onChange }) =>
                                            <VacancyControlButtons>
                                                <VacancyLinkButtonWrapper
                                                    noVacancies={this.noVacancies()}
                                                    onMouseEnter={() => this.enter({ row: index, item: i })}
                                                    onMouseLeave={this.leave}>
                                                    <VacancyLinkButton
                                                        onSettingsToggle={this.props.onSettingsToggle}
                                                        settings={this.props.allSettings}
                                                        options={{
                                                            path: `${index}.${i}`,
                                                            titleWrapper: title => `<h3 class="ql-align-center">${title}</h3>`,
                                                            show: ['vacancies']
                                                        }} />
                                                </VacancyLinkButtonWrapper>
                                                { item.image &&
                                                    <RemoveImageButton
                                                        className='btn-hidden-vacancy'
                                                        onClick={() => this.removeImage(onChange, index, i)}>
                                                        <FontAwesomeIcon icon={faImage} />
                                                    </RemoveImageButton>
                                                }
                                                <UploadImageWrapper className='btn-hidden-vacancy'>
                                                    <VacancyButton>
                                                        <FontAwesomeIcon icon={faImage} />
                                                    </VacancyButton>
                                                    <input
                                                        type='file'
                                                        accept='image/*'
                                                        onChange={e => this.uploadImage(e, onChange, index, i)} />
                                                </UploadImageWrapper>
                                                { row.length > 1 &&
                                                    <VacancyButton
                                                        className='btn-hidden-vacancy'
                                                        onMouseEnter={() => this.enter({ row: index, item: i })}
                                                        onMouseLeave={this.leave}
                                                        onClick={() => this.removeVacancy(onChange, index, i)}>
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                    </VacancyButton>
                                                }
                                            </VacancyControlButtons>
                                        }
                                    </DataContext.Consumer>
                                }
                                <TitleCard {...settings}>
                                    <Block html={item.title} allowColor={false} name={`vacancies.data.${index}.${i}.title`} />
                                </TitleCard>
                                <ControlsCard>
                                    <Button small view {...settings} onClick={() => this.openedModal(index, i)}>
                                        { settings.btnViewText }
                                    </Button>
                                    { formUrl ? (
                                        <Link to={{
                                            pathname: `${formUrl}/${pathOr('', ['modal', 'vacancies', index, i], allSettings)}`,
                                            search: `?vacancy=${getVacancyString(item.title)}${search ? `&${drop(1, search)}` : ''}`,
                                        }}>
                                            <Button small {...settings}>{ settings.btnText }</Button>
                                        </Link>
                                    ) : (
                                        <Button small {...settings}>{ settings.btnText }</Button>
                                    )}
                                </ControlsCard>
                                <Modal
                                    className='vacancy-item-modal'
                                    visible={this.isOpenedModal({ item: i, row: index })}
                                    title={getVacancyString(item.title)}
                                    footer={null}
                                    zIndex={100}
                                    width='80%'
                                    onCancel={this.closeModal}>
                                    <VacancyDescription
                                        settings={this.props.allSettings}
                                        opened={this.props.opened}
                                        onSettingsToggle={this.props.onSettingsToggle}
                                        vacancy={item}
                                        row={index}
                                        index={i}
                                        options={{ path: `${index}.${i}`, titleWrapper: title => `<h3 class="ql-align-center">${title}</h3>` }} />
                                </Modal>
                            </Card>
                        )}
                    </VacancyBlock>
                )}
            </div>
            { this.renderAddButton(edit) }
        </Fragment>;
    }

    renderAddButton = edit => {
        const { settings } = this.props;

        return edit &&
            <DataContext.Consumer>
                { ({ onChange }) =>
                    <AddVacancyButton
                        {...settings}
                        className='btn-hidden-vacancy'
                        onClick={() => this.addVacancyRow(onChange)}>
                        <FontAwesomeIcon icon={faPlus} />
                    </AddVacancyButton>
                }
            </DataContext.Consumer>;
    }

    renderVacancies = edit => {
        const { settings, data } = this.props;
        const { blockText } = settings;

        return <ListCard {...settings}>
            <Wrapper blockText={blockText}>
                <Flex flexWrap='wrap'>
                    { blockText &&
                        <BoxVacanciesDescription width={[1,1,1/3]} px={[15,15, 0]}>
                            <Block html={data.vacancies.description} name='vacancies.description' />
                        </BoxVacanciesDescription>
                    }
                    <SmallVacancyBlock
                        width={blockText ? [1,1,2/3] : 1}
                        pl={blockText ? [0,0,50] : 0}>
                        { data.vacancies.data.map((row, index) =>
                            <VacancyBlock
                                flexWrap='wrap'
                                key={`vacancy-row-${index}`}
                                hover={this.isHover(index)}>
                                { edit &&
                                    <VacancyBlockControlButtons>
                                        { this.renderBlockControlButtons(row, index) }
                                    </VacancyBlockControlButtons>
                                }
                                { row.map((item, i) =>
                                    <Box
                                        key={`vacancy-small-${index}-${i}`}
                                        width={[1,1,1/row.length]}
                                        p={15}>
                                        <BorderCard
                                            {...settings}
                                            hover={this.isHover(index, i)}>
                                            { edit &&
                                                <VacancyLinkButtonWrapper
                                                    small
                                                    noVacancies={this.noVacancies()}
                                                    onMouseEnter={() => this.enter({ row: index, item: i })}
                                                    onMouseLeave={this.leave}>
                                                    <VacancyLinkButton
                                                        onSettingsToggle={this.props.onSettingsToggle}
                                                        settings={this.props.allSettings}
                                                        options={{
                                                            path: `${index}.${i}`,
                                                            titleWrapper: title => `<h3 class="ql-align-center">${title}</h3>`,
                                                            show: ['vacancies']
                                                        }} />
                                                </VacancyLinkButtonWrapper>
                                            }
                                            { edit && row.length > 1 &&
                                                <DataContext.Consumer>
                                                    { ({ onChange }) =>
                                                        <RemoveSmallVacancyButton
                                                            className='btn-hidden-vacancy'
                                                            onMouseEnter={() => this.enter({ row: index, item: i })}
                                                            onMouseLeave={this.leave}
                                                            onClick={() => this.removeVacancy(onChange, index, i)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </RemoveSmallVacancyButton>
                                                    }
                                                </DataContext.Consumer>
                                            }
                                            <Block
                                                html={item.title}
                                                allowColor={false}
                                                allowFontSize={false}
                                                name={`vacancies.data.${index}.${i}.title`} />
                                        </BorderCard>
                                    </Box>
                                )}
                            </VacancyBlock>
                        )}
                        { this.renderAddButton(edit) }
                    </SmallVacancyBlock>
                </Flex>
            </Wrapper>
        </ListCard>;
    }

    render() {
        const { data, settings } = this.props;

        return <EditContext.Consumer>
            { edit =>
                <VacanciesContainer {...settings}>
                    <div className='vacancies-title'>
                        <Block html={data.vacancies.header} name='vacancies.header' />
                    </div>
                    { settings.block ? this.renderBlockVacancies(edit) : this.renderVacancies(edit) }
                </VacanciesContainer>
            }
        </EditContext.Consumer>;
    }
}

export default withSettings(withRouter(Vacancies), 'vacancies');

import axios from 'axios';

import { VACANCY, FILE, DICTIONARY, FINDY_VACANCY } from '../../constants/urls';

export const getVacancyHandler = ({ payload }) => axios.get(VACANCY.stringify({ id: payload.id, vacancyId: payload.vacancyId }));
export const getDictionaryHandler = ({ payload }) => axios.get(DICTIONARY.stringify({ loadname: payload }));

export const postVacancyHandler = ({ payload: { id, ...form } }) =>
    axios.post(VACANCY.stringify({ id }), form);

export const postFindyVacancyHandler = ({ payload: { id, ...form } }) =>
    axios.post(FINDY_VACANCY.stringify({ id }), form, { baseURL: '/api' });

export const postFileHandler = ({ payload, params }) =>
    axios.post(FILE.stringify({ vacancyId: params.vacancyId }), payload, { baseURL: '/api' });

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Webcam from 'react-webcam';
import { Button } from 'antd';
import cx from 'classnames';

import MediaLength from './MediaLength';
import { WebcamWrapper, CompleteControls } from './StyledComponents';

const videoConstraints = {
    width: 640,
    height: 480,
    facingMode: 'user'
};

export default class WebcamComponent extends Component {
    static propTypes = {
        onSave: PropTypes.func
    };

    state = {
        recording: false,
        data: null
    };

    onUserMedia = () => {
        const stream = this.webcam.stream;

        this.MediaRecorder = new MediaRecorder(stream);
        this.MediaRecorder.ondataavailable = ({ data }) => {
            this.setState({ data });
        }
    }

    startRecord = () => {
        this.MediaRecorder.start();
        this.setState({ recording: true });
    }

    stopRecord = () => {
        this.MediaRecorder.stop();
        this.setState({ recording: false });
    }

    cancel = () => this.setState({ data: null });

    save = () => {
        const { data } = this.state;
        const reader = new FileReader();

        reader.readAsDataURL(data);
        reader.onload = () => this.props.onSave(reader.result, data);
    }

    renderPreview = () => {
        return <video
            width={640}
            height={480}
            controls>
            <source src={URL.createObjectURL(this.state.data)} type='video/webm' />
        </video>;
    }

    render() {
        const { recording, data } = this.state;

        return <WebcamWrapper>
            { data && this.renderPreview() }
            <Webcam
                className={cx('webcam', { hidden: data })}
                ref={node => this.webcam = node}
                videoConstraints={videoConstraints}
                onUserMedia={this.onUserMedia} />
            { data ?
                <CompleteControls>
                    <Button type='danger' onClick={this.cancel}>
                        Отмена
                    </Button>
                    <Button type='primary' onClick={this.save}>
                        Сохранить
                    </Button>
                </CompleteControls> :
                <Fragment>
                    <MediaLength recording={recording} data={data} />
                    <Button onClick={recording ? this.stopRecord : this.startRecord}>
                        { recording ? 'Остановить запись' : 'Начать запись' }
                    </Button>
                </Fragment>
            }
        </WebcamWrapper>;
    }
}

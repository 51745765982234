import { prop, propEq, find, any } from 'ramda';

import { TEMPLATES } from '../constants/templates';
import { OG_IMAGE } from '../constants/urls';

const getLandingData = ({ parts = [] }) => {
    const landingString = prop('data', find(propEq('type', 'web_constructor'), parts));

    if (landingString) {
        const landingData = JSON.parse(landingString);
        return any(propEq('value', landingData.template), TEMPLATES) ? landingData : null;
    } else {
        return null;
    }
}

export default getLandingData;

export const getOgImageUrl = (image, id) => {
    if (image) {
        return OG_IMAGE.stringify({
            id,
            image: 'ogimage.png',
        });
    }

    return null;
}

import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    margin-bottom: 30px;
`;

const Logotype = styled.div`
    background-size: contain;
    background-repeat: no-repeat;
    background-position: ${({ position }) =>
        position === 'left' ? 0 : position === 'right' ? '100%' : '50%'
    } 50%;
    width: 100%;
    height: 80px;
    background-image: url('${({logo}) => logo}');
`;

export default class Logo extends Component {
    render() {
        const { logo, position } = this.props;

        return <Wrapper className='logo'>
            <Logotype position={position} logo={logo} />
        </Wrapper>;
    }
}

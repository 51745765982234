
import Toolbox from '../../assets/img/toolbox.svg';
import Rocket from '../../assets/img/rocket.svg';
import Iheart from '../../assets/img/iheart.svg';

import { FOOTER_HTML } from './default';
import { GREEN_OPTION_HTML_1, GREEN_OPTION_HTML_2, GREEN_OPTION_HTML_3 } from './green';

export const GRAY_HEADER_HTML = `
    <h1 class="ql-align-center">
        <span style="color: #fff">Построй карьеру в банке!</span>
    </h1>
`;

export const GRAY_DESCRIPTION_HTML = `
    <p>
        <span style="color: #000">Миссия нашей компании — повышение доступности современного комфортного жилья для россиян и преображение облика городской среды. Совместно с надежными партнерами мы реализуем инициативы в жилищной сфере и помогаем людям разобраться в вопросах ипотеки и рынка жилья.</span>
    </p>
`;

export const GRAY_VACANCIES_HEADER_HTML = `
    <h4>
        <strong style="color: #000">Сейчас у нас открыты вакантные должности по следующим направлениям:</strong>
    </h4>
`;

export const GRAY_VACANCY_HEADER_HTML_1 = `
    <h4>Входящие обращения</h4>
`;

export const GRAY_VACANCY_HEADER_HTML_2 = `
    <h4>Дистанционные продажи</h4>
`;

export const GRAY_VACANCY_HEADER_HTML = `
    <h4>Вакансия</h4>
`;

export const GRAY_VACANCY_TEXT_HTML = `
    <p>
        <span style="color: #000">Требования:</span>
    </p>
    <ul>
        <li><span style="color: #666;">Образование не ниже среднего</span></li>
        <li><span style="color: #666;">Опыт работы в КЦ или Банке не менее года</span></li>
        <li><span style="color: #666;">Опыт работы с IP телефонией</span></li>
        <li><span style="color: #666;">Опыт работы с базами даных</span></li>
        <li><span style="color: #666;">Уверенный пользователь Excel</span></li>
        <li><span style="color: #666;">Быстрый набор текста, поиск информации в интернете, уверенное владение ПК</span></li>
        <li><span style="color: #666;">Грамотная речь. Четкая дикция</span></li>
        <li><span style="color: #666;">Базовые знания в области банковских продуктов и услуг будут являться преимуществом</span></li>
    </ul>
    <br />
    <p>
        <span style="color: #000">Основные должностные обязанности:</span>
    </p>
    <ul>
        <li><span style="color: #666;">Прием звонков на входящей линии в соответствии с KPIs</span></li>
        <li><span style="color: #666;">Обработка заявок с сайта Банка и дистанционных обращений</span></li>
        <li><span style="color: #666;">Продажа и доп. продажа продуктов Банка</span></li>
        <li><span style="color: #666;">Участие в разработке скриптов</span></li>
        <li><span style="color: #666;">Обучение новых сотрудников</span></li>
        <li><span style="color: #666;">Подготовка отчетности</span></li>
        <li><span style="color: #666;">Консультирование клиентов на входящей линии, решение вопроса клиента</span></li>
        <li><span style="color: #666;">Исходящий обзвон с целью увеличения/удержания клиентской базы Банка</span></li>
        <li><span style="color: #666;">Обзвон клиентов Банка в рамках маркетинговых компаний и заявок с сайта</span></li>
        <li><span style="color: #666;">Исполнение доп.функционала и поручений Руководителя Группы</span></li>
    </ul>
    <br />
    <p>
        <span style="color: #000">Условия:</span>
    </p>
    <ul>
        <li><span style="color: #666;">Оформление в штат банка с первого дня</span></li>
        <li><span style="color: #666;">Гибкий график 2/2 либо 5/2 с плавающим началом рабочего дня</span></li>
        <li><span style="color: #666;">Достойный уровень заработной платы</span></li>
        <li><span style="color: #666;">Возможность карьерного роста и развития в структуре банка</span></li>
    </ul>
`;

export default {
    header: GRAY_HEADER_HTML,
    description: GRAY_DESCRIPTION_HTML,
    options: [
        { html: GREEN_OPTION_HTML_1, image: Toolbox },
        { html: GREEN_OPTION_HTML_2, image: Rocket },
        { html: GREEN_OPTION_HTML_3, image: Iheart }
    ],
    vacancies: {
        header: GRAY_VACANCIES_HEADER_HTML,
        data: [
            { title: GRAY_VACANCY_HEADER_HTML_1, content: GRAY_VACANCY_TEXT_HTML },
            { title: GRAY_VACANCY_HEADER_HTML_2, content: GRAY_VACANCY_TEXT_HTML }
        ]
    },
    footer: {
        header: FOOTER_HTML
    }
};

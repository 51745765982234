import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { getThumbnailedBackground } from '../../../utils/thumbnail';
import withSettings from '../../hocs/withSettings';
import StyledButton from '../StyledButton';
import Block from '../../Block';
import BotButton from '../BotButton';
import IsMobileContext from '../../contexts/IsMobileContext';

const ImageBg = styled.div`
    background-image: ${getThumbnailedBackground()};
    background-position: ${prop('backgroundPosition')};

    @media screen and (max-width: 767px) {
        background-image: ${getThumbnailedBackground(true)};
    }

    ${({ isMobile }) => {
        return isMobile ? css`@media screen and (orientation:landscape) {
            background-image: ${getThumbnailedBackground(true, true)};
        }` : ''
    }}
`;

const BotButtonWrapper = styled.span`
    padding-left: 10px;
    display: inline-block;
    vertical-align: top;
`;

class Header extends Component {
    render() {
        const { settings, formUrl, data, commonSettings } = this.props;

        return <div>
            <div className='top-red'>
                <StyledButton settings={settings} formUrl={formUrl} ancor />
                <BotButtonWrapper>
                    <BotButton settings={commonSettings} ancor />
                </BotButtonWrapper>
            </div>
            <header>
                <div className='row'>
                    <div className='box-1'>
                        <IsMobileContext.Consumer>
                            { isMobile =>
                                <ImageBg
                                    backgroundImage={settings.backgroundImage}
                                    thumbnail={settings.thumbnail}
                                    backgroundPosition={settings.backgroundPosition}
                                    backgroundImageMobileHorizontal={settings.backgroundImageMobileHorizontal}
                                    isMobile={isMobile}
                                    className='img-bg' />
                            }
                        </IsMobileContext.Consumer>
                    </div>
                    <div className='box-2 parallelogram'>
                        <div className='parallelogram-container'>
                            <div className='parallelogram-content'>
                                <Block html={data.header} name='header' />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>;
    }
}

export default withSettings(Header, 'header');

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { Select } from 'antd';
import cx from 'classnames';
import { isNil } from 'ramda';

import { set, format } from 'date-fns'

import withFieldWrapper from '../hocs/withFieldWrapper';
import { YEAR_MASK } from '../../constants/masks';
import MONTHS from '../../constants/months';

class Monthpicker extends Component {
    static propTypes = {
        input: PropTypes.object
    };

    state = {
        month: undefined,
        year: undefined
    };

    onChange = (month, year) => {
        this.props.input.onChange(
            !isNil(month) && year ?
                format(set(new Date(), { year, month }), 'yyyyMM') :
                undefined
        );
    }

    onChangeMonth = month => {
        this.setState(prev => {
            this.onChange(month, prev.year);
            return { month };
        });
    }

    onChangeYear = e => {
        const { value: year } = e.target;

        this.setState(prev => {
            this.onChange(prev.month, year);
            return { year };
        });
    }

    render() {
        const { error } = this.props;
        const { month, year, required } = this.state;

        return <div className='monthpicker'>
            <Select
                className={cx('form-control month', { error: error && isNil(month) })}
                value={month}
                onChange={this.onChangeMonth}
                allowClear={!required}>
                { MONTHS.map((month, index) =>
                    <Select.Option key={`month-${index}`} value={index}>
                        { month }
                    </Select.Option>
                )}
            </Select>
            <MaskedInput
                className={cx('ant-input form-control year', { error: error && !year })}
                value={year}
                onChange={this.onChangeYear}
                mask={YEAR_MASK}
                placeholderChar={'\u2000'}
                keepCharPositions={false}
                guide={true} />
        </div>;
    }
}

export default withFieldWrapper(Monthpicker);

import UrlPattern from 'url-pattern';

const construct = url => new UrlPattern(`(*)${url}`);

export const FORM_LINK = construct('(/:id)/form');
export const SUCCESS_LINK = construct('(/:id)/form(/:vacancyId)/success');

export const VACANCY = construct('/v1/landing/vacancy(/:id)(/:vacancyId)');
export const FINDY_VACANCY = construct('/public/vacancy/:id/applicant');
export const FILE = construct('/landing/public/file/:vacancyId');
export const DICTIONARY = construct('/v1/landing/dictionary/:loadname');

export const BACKGROUND_IMAGE = construct('/v1/landing/vacancy/:id/background');

export const OG_IMAGE = construct('(/:id)(/:image)');

export const POST_FINDY_FILE = construct('/api/landing/public/file/:vacancyId');

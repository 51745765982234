import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import { last, path } from 'ramda';
import { Field } from 'react-final-form';

import { fieldConfig } from '../../constants/fieldConfig';
import { COMMON_STR } from '../../constants/fieldTypes';
import withFieldWrapper from '../hocs/withFieldWrapper';
import { getValidator }  from '../../utils/validation';
import { getOtherId }  from '../../utils/fields';

class RadioButtons extends Component {
    static propTypes = {
        options: PropTypes.array,
        onChange: PropTypes.func,
        field: PropTypes.object,
        customFieldValue: PropTypes.string,
        valuePath: PropTypes.string
    };

    static defaultProps = {
        options: [],
        valuePath: 'id'
    };

    onChange = (e) => {
        const { input: { onChange }, options, change, customFieldValue } = this.props;
        const { value } = e.target;

        onChange(value);

        if (path(['id'], last(options)) !== value) {
            change(customFieldValue, undefined);
        }
    }

    renderOptions() {
        const { options, valuePath } = this.props;

        return options.map(option =>
            <Radio key={option[valuePath]} value={option[valuePath]} className='radio'>
                { option.name }
            </Radio>
        );
    }

    render() {
        const { input: { value, name }, customFieldValue, options, required } = this.props;

        return (
            <div className="other-radio">
                <Radio.Group
                    name={name}
                    className='radio-box'
                    onChange={this.onChange}
                    value={value}>
                    { this.renderOptions() }
                </Radio.Group>
                <br/>
                { getOtherId(options) === value &&
                    <Field
                        name={customFieldValue}
                        component={fieldConfig[COMMON_STR]}
                        validate={getValidator({isRequired: required})} />
                }
            </div>
        );
    }
};

export default withFieldWrapper(RadioButtons);

import React, { Component } from 'react';
import styled from 'styled-components';
import { indexOf, prop } from 'ramda';

import withSettings from '../../hocs/withSettings';
import Block from '../../Block';
import { BULLETS } from '../../../constants/bullets';

export const StyledModalContainer = styled.div`
    background: ${prop('background')};
    padding: 24px;
    ul li::before, .ql-editor ul > li:before {
        content: '\\e90${({ bulletType }) => indexOf(bulletType, BULLETS)}';
        color: ${prop('bulletColor')};
    }
`;

class VacancyDescription extends Component {
    render() {
        const { settings, vacancy, index, name } = this.props;

        return <StyledModalContainer {...settings}>
            <Block html={vacancy.content} name={name || `vacancies.data.${index}.content`} allowLists />
        </StyledModalContainer>
    }
}

export default withSettings(VacancyDescription, 'modal');

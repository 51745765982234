import { toSuccess, defaultKey, noParamsKey } from 'react-async-client';
import { assoc, path, pathOr, when, is, compose, join, flatten, toPairs } from 'ramda';

import { GET_VACANCY } from '../constants/actionTypes';

export const getPath = when(is(Object), compose(join('_'), flatten, toPairs));

export default (state, action, defaultReducer) => {
    switch (action.type) {
        case toSuccess(GET_VACANCY): {
            const vacancyId = path(['requestAction', 'payload', 'vacancyId'], action);
            const statePath = getPath(pathOr(action.params || noParamsKey, ['params'], action.requestAction));

            return {
                ...state,
                [statePath]: {
                    ...action.payload,
                    fieldList: vacancyId ? [] : action.payload.fieldList,
                    vacanciesFieldList: vacancyId ?
                        assoc(vacancyId, action.payload.fieldList, state[defaultKey].vacanciesFieldList || {}) :
                        state[defaultKey].vacanciesFieldList
                }
            };
        }
        default:
            return defaultReducer(state, action);
    }
}

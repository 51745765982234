import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import Webcam from 'react-webcam';
import cx from 'classnames';

import { CompleteControls, WebcamWrapper, CaptureButton } from './StyledComponents';

export default class Image extends Component {
    static propTypes = {
        onSave: PropTypes.func
    };

    state = {
        data: null
    };

    cancel = () => this.setState({ data: null });

    save = () => this.props.onSave(this.state.data);

    capture = () => this.setState({
        data: this.webcam.getScreenshot()
    });

    render() {
        const { data } = this.state;

        return <WebcamWrapper>
            { data ?
                <Fragment>
                    <img alt='webcam-img' src={data} />
                    <CompleteControls>
                        <Button type='danger' onClick={this.cancel}>
                            Отмена
                        </Button>
                        <Button type='primary' onClick={this.save}>
                            Сохранить
                        </Button>
                    </CompleteControls>
                </Fragment> :
                <Fragment>
                    <Webcam
                        className={cx('webcam', { hidden: data })}
                        ref={node => this.webcam = node}
                        audio={false}
                        screenshotQuality={1}
                        screenshotWidth={640}
                        screenshotFormat='image/jpeg' />
                    <CaptureButton type='primary' onClick={this.capture}>
                        Сделать фото
                    </CaptureButton>
                </Fragment>
            }
        </WebcamWrapper>
    }
}

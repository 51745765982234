import React from 'react';
import Masked from 'react-text-mask';

import withFieldWrapper from '../hocs/withFieldWrapper';

const MaskedInput = ({ input: { value, onChange, name }, mask, pipe }) => (
    <Masked
        name={name}
        value={value}
        mask={mask}
        onChange={onChange}
        className="ant-input form-control"
        placeholderChar={'\u2000'}
        keepCharPositions={false}
        guide={true}
        pipe={pipe}
    />
);

export default withFieldWrapper(MaskedInput);

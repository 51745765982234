import React, { Component } from 'react';
import cx from 'classnames';

export default WrappedComponent =>
    class FieldWrapper extends Component {
        render() {
            const { label, meta: { submitFailed, error }, required, ...props } = this.props;

            return (
                <div className={cx('form-group', { 'error-input': error && submitFailed })}>
                    <label>{label} { !required && <small style={{ color: '#555' }}>(опционально)</small> }</label>
                    <WrappedComponent {...props} error={error && submitFailed} />
                    { submitFailed && error && <div className="error-msg">{ error }</div> }
                </div>
            );
        }
    };

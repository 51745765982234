import React, { Component } from 'react';
import { Spin } from 'antd';
import { asyncConnect } from 'react-async-client';

import Landing from './Landing';
import Form from './Form';
import FindyForm from './FindyForm';
import Success from './Success';
import NoMatch from './NoMatch';
import { VacancyClosed, ErrorFromServer } from './VacancyErrors';
import { getVacancy } from '../actions/asyncActions';
import { PUBLISHED } from '../constants/states';
import TrackPageView from './TrackPageView';
import getLandingData from '../utils/getLandingData';
import { path } from 'ramda';

class VacancyRoute extends Component {
    getComponent = (isFindyForm) => {
        const { match: { params } } = this.props;

        if (params.success) {
            return Success;
        }

        if (params.form) {
            return isFindyForm ? FindyForm : Form;
        } else {
            return Landing;
        }
    }

    render() {
        const { getVacancy, match: { params }, history, location } = this.props;
        const landingData = getLandingData(getVacancy.data);
        const isFindyForm = path(['data', 'settings', 'common', 'jobotForm'], landingData);
        const Component = this.getComponent(isFindyForm);

        if (getVacancy.meta.pending && !getVacancy.meta.lastSucceedAt) return <Spin className='main-spinner' />;
        if (getVacancy.meta.error) return <ErrorFromServer error={getVacancy.meta.error} />;
        if (getVacancy.data.state !== PUBLISHED) return <VacancyClosed action={getVacancy} />;
        if (!landingData) return <NoMatch />;

        return (
            <div className='vacancy-route'>
                { process.env.NODE_ENV === 'production' && <TrackPageView action={getVacancy} /> }
                <Component
                    key={`${params.vacancyId}-${getVacancy.data.id}`}
                    getVacancy={getVacancy}
                    id={params.id}
                    vacancyId={params.vacancyId}
                    history={history}
                    location={location}
                    params={params}
                />
            </div>
        );
    }
}

const getPayload = ({ match: { params } }) => ({
    id: params.id,
    vacancyId: params.vacancyId,
});

export default asyncConnect({
    getVacancy: getVacancy
        .withPayload(getPayload)
        .withOptions({ dispatchOnMount: true, dispatchOnUpdate: true })
})(VacancyRoute);

import React, { Component } from 'react';
import { path } from 'ramda';
import { FileOutlined } from '@ant-design/icons';

import Loader from './Loader';

export default class DocumentLoader extends Component {
    getPreview = () => {
        const name = path(['value', 'name'], this.props.input);
        const url = path(['value', 'requestValue'], this.props.input);

        return url ? (
            <a href={url} target='_blank' rel='noopener noreferrer'>{name}</a>
        ) : name || 'Удалить';
    }

    render() {
        return <Loader
            {...this.props}
            preview={this.getPreview()}
            uploadLabel='Загрузить документ'
            icon={<FileOutlined />}
        />;
    }
}

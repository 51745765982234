import axios from 'axios';
import { toLower } from 'ramda';

import ERRORS from '../constants/errors';
import { mapObjectKeys } from './ramdaAdditions';
import { BASE_URL } from '../constants/baseUrls';

export class HttpError {
    constructor(error) {
        const { response = {} } = error;

        this.origin = error;

        this.headers = mapObjectKeys(toLower, response.headers);
        this.data = response.data;
        this.status = response.status;
        this.code = parseInt(this.headers['x-error-code'], 10) || 'unknown';
        this.message = ERRORS[this.code] || '';
    }
}

export function setBaseUrl(base = BASE_URL) {
    axios.defaults.baseURL = base;
}

export function setLanguage(language = 'ru-RU,ru') {
    axios.defaults.headers.common['Accept-Language'] = language;
}

export function provideInterceptors(dispatch) {
    axios.interceptors.response.use(
        (response) => response.data,
        (error) => {
            const httpError = new HttpError(error);
            return Promise.reject(httpError);
        }
    );
}

export const getFullUrl = (urlPattern, params) => `${BASE_URL}${urlPattern.stringify(params)}`;

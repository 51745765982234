export const THEMES = {
    'ancor':    ['default'],
    'default':  ['default', '1', '2', '3', '4', '5', '6'],
    'red':      ['default', '1', '2', '3', '4', '5', '6'],
    'green':    ['default', '1', '2', '3', '4', '5', '6'],
    'blue':     ['default', '1', '2', '3', '4', '5', '6'],
    'gray':     ['default', '1', '2', '3', '4', '5', '6'],
    'lime':     ['default', '1', '2', '3', '4', '5', '6'],
    'iceberg':  ['default']
};

export const TEMPLATES = [
    { value: 'ancor', label: 'Ancor', themes: THEMES.ancor },
    { value: 'default', label: 'Синий', themes: THEMES.default },
    { value: 'red', label: 'Красный', themes: THEMES.red },
    { value: 'green', label: 'Зеленый', themes: THEMES.green },
    { value: 'blue', label: 'Голубой', themes: THEMES.blue },
    { value: 'gray', label: 'Серый', themes: THEMES.gray },
    { value: 'lime', label: 'Салатовый', themes: THEMES.lime },
    { value: 'iceberg', label: 'Айсберг', themes: THEMES.iceberg }
];

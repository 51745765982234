import React from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';

import CheckboxInput from './Checkbox';
import CheckboxGroup, { OtherCheckboxGroup } from './CheckboxGroup';
import Input from './Input';
import MaskedInput from './MaskedInput';
import RadioButtons from './RadioButtons';
import SearchInput from './SearchInput';
import Select, { OtherSelect } from './Select';
import OtherRadioButtons from './OtherRadioButtons';
import withLoad from '../hocs/withLoad';
import { PHONE_MASK, PHONE_MASK_EIGHT, YEAR_MASK } from '../../constants/masks';
import { getVacancy } from '../../actions/asyncActions';

const getMask = (value) => value && value[0] === '8' ? PHONE_MASK_EIGHT : PHONE_MASK;

const stateToProps = (state) => ({
    options: getVacancy.selectData(state).desiredPosition,
    valuePath: 'name',
    loadname: 'desiredPosition',
});

export const Checkbox = props => <CheckboxInput field={props} inputLabel={props.label} {...props} label={null} />;
export const InputNumber = props => <Input {...props} type="number" />;
export const InputPhone = props => <MaskedInput {...props} mask={getMask} />;
export const Yearpicker = props => <MaskedInput {...props} mask={YEAR_MASK} />;
export const SelectWithLoad = withLoad(Select);
export const SearchSelectWithLoad = withLoad(SearchInput);
export const OtherSelectWithLoad = withLoad(OtherSelect);
export const CheckboxGroupWithLoad = withLoad(CheckboxGroup);
export const OtherCheckboxGroupWithLoad = withLoad(OtherCheckboxGroup);
export const OtherRadioButtonsWithLoad = withLoad(OtherRadioButtons);
export const DesiredPosition = connect(stateToProps)(RadioButtons);

export const Combo = (props) => {
    if (path(['options', 'multiple'], props)) {
        return path(['options', 'userValueAllowed'], props) ? (
            <OtherCheckboxGroupWithLoad {...props} noSum={true} />
        ) : (
            <CheckboxGroupWithLoad {...props} noSum={true} />
        );
    }

    return <SelectWithLoad {...props} />;
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';

import Webcam from './Webcam';
import { StyledModal, ErrorContentWrapper } from './StyledComponents';

export default class WebcamModal extends Component {
    static propTypes = {
        visible: PropTypes.bool,
        closeModal: PropTypes.func,
        available: PropTypes.bool,
        onSave: PropTypes.func,
        uploadButton: PropTypes.node
    };

    render() {
        const { width, visible, closeModal, onSave, available, uploadButton } = this.props;

        return <StyledModal
            width={width}
            visible={visible}
            onCancel={closeModal}
            title='Камера'
            footer={null}
            destroyOnClose>
            { available ?
                <Webcam onSave={onSave} /> :
                <ErrorContentWrapper>
                    <Alert
                        type='error'
                        message='Доступ к камере заблокирован. Разрешите доступ к камере в настройках браузера' />
                    { uploadButton }
                </ErrorContentWrapper>
            }
        </StyledModal>;
    }
}

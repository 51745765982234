import styled from 'styled-components';
import { Button, Modal } from 'antd';

export const UploadButton = styled(Button)`
    position: relative;
    overflow: hidden;
    input {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
    }
`;

export const PreviewWrapper = styled.div`
    display: flex;
    align-items: center;
    video,
    audio,
    img {
        margin-right: 15px;
    }

    button {
        margin-left: 15px;
    }

    .ant-spin {
        margin-right: 15px;
    }
`;

export const CompleteControls = styled(Button.Group)`
    margin-top: 15px;
`;

export const Wrapper = styled.div`
    text-align: center;
`;

export const StyledModal = styled(Modal)`
    top: 30px !important;
    width: 700px !important;
`;

export const WebcamWrapper = styled(Wrapper)`
    .webcam.hidden {
        display: none;
    }
`;

export const CaptureButton = styled(Button)`
    margin-top: 10px;
`;

export const ErrorContentWrapper = styled.div`
    text-align: center;
    .ant-alert {
        margin-bottom: 15px;
    }
`;

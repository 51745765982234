import React, { Component } from 'react';
import { Flex, Box } from '@rebass/grid';
import { Link, withRouter } from 'react-router-dom';

import withSettings from '../../hocs/withSettings';
import { Container, Button, BoxButton, Footer } from './styledComponents';
import Block from '../../Block';

class FooterComponent extends Component {
    render() {
        const { data, settings, formUrl, location: { search }} = this.props;
        const button = <Button {...settings}>{ settings.btnText }</Button>;

        return <Footer {...settings} py={40}>
            <Container>
                <Flex width={1} flexWrap='wrap'>
                    <Box width={[1,1,1/2,1/2]}>
                        <Block html={data.footer.header} name='footer.header' />
                    </Box>
                    <BoxButton width={[1,1,1/2,1/2]}>
                        { formUrl ? <Link to={{
                            pathname: formUrl,
                            search
                        }}>{ button }</Link> : button }
                    </BoxButton>
                </Flex>
            </Container>
        </Footer>;
    }
}

export default withSettings(withRouter(FooterComponent), 'footer');

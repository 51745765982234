import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import styled from 'styled-components';
import Recorder from 'recorder-js';

import MediaLength from './MediaLength';
import { Wrapper } from './StyledComponents';

const CompleteControls = styled(Button.Group)`
    margin-top: 15px;
`;

export default class Audio extends Component {
    static propTypes = {
        onSave: PropTypes.func
    };

    state = {
        recording: false,
        data: null
    };

    recorder = new Recorder(
        new (window.AudioContext || window.webkitAudioContext)(),
        { type: 'audio/webm'}
    );

    componentDidMount() {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => this.recorder.init(stream));
    }

    startRecord = () => {
        this.recorder.start().then(() =>
            this.setState({ recording: true })
        );
    }

    stopRecord = () => {
        this.recorder.stop().then(({ blob }) =>
            this.setState({ data: blob, recording: false })
        );
    }

    cancel = () => this.setState({ data: null });

    save = () => {
        const reader = new FileReader();

        reader.readAsDataURL(this.state.data);
        reader.onload = () => this.props.onSave(reader.result);
    }

    render() {
        const { recording, data } = this.state;

        return  <Wrapper>
            { data ?
                <Fragment>
                    <div>
                        <audio controls>
                            <source src={URL.createObjectURL(data)}  />
                        </audio>
                    </div>
                    <CompleteControls>
                        <Button type='danger' onClick={this.cancel}>
                            Отмена
                        </Button>
                        <Button type='primary' onClick={this.save}>
                            Сохранить
                        </Button>
                    </CompleteControls>
                </Fragment> :
                <Fragment>
                    <MediaLength recording={recording} data={data} />
                    <Button onClick={recording ? this.stopRecord : this.startRecord}>
                        { recording ? 'Остановить запись' : 'Начать запись' }
                    </Button>
                </Fragment>
            }
        </Wrapper>;
    }
}

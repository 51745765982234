import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop, path } from 'ramda';

import Block from '../Block';
import withSettings from '../hocs/withSettings';
import StyledButton from './StyledButton';
import BotButton from './BotButton';

const StyledFooter = styled.footer`
    background-color: ${prop('background')};
    display: ${({ hideFooter }) => hideFooter ? 'none' : 'block'};
`;

const ButtonWrapper = styled.span`
    padding-left: 27px;
`;

class Footer extends Component {
    static propTypes = {
        settings: PropTypes.object,
        data: PropTypes.object,
        template: PropTypes.string,
        formUrl: PropTypes.string
    };

    render() {
        const { settings, data, template, formUrl, allSettings, commonSettings } = this.props;
        const hideFooter = path(['header', 'hideFooter'], allSettings);

        return <StyledFooter {...settings} hideFooter={hideFooter}>
            <div className='wrap-block'>
                <div className='row'>
                    <div className='col-1'>
                        <Block html={data.footer.header} name='footer.header' />
                    </div>
                    <div className='col-2'>
                        { template === 'ancor' ?
                            <ButtonWrapper >
                                <StyledButton settings={settings} formUrl={formUrl} />
                                <BotButton settings={commonSettings} ancor />
                            </ButtonWrapper> :
                            <StyledButton settings={settings} formUrl={formUrl} />
                        }
                        { (template === 'blue' || template === 'iceberg') && <Block html={data.footer.text} name='footer.text' /> }
                    </div>
                </div>
            </div>
        </StyledFooter>;
    }
}

export default withSettings(Footer, 'footer');

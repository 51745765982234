import Datepicker from '../components/formComponents/Datepicker';
import Monthpicker from '../components/formComponents/Monthpicker';
import Input from '../components/formComponents/Input';
import OPD from '../components/formComponents/OPD';
import FileLoader from '../components/formComponents/files/FileLoader';

import {
    InputNumber,
    InputPhone,
    SearchSelectWithLoad,
    CheckboxGroupWithLoad,
    DesiredPosition,
    OtherCheckboxGroupWithLoad,
    OtherSelectWithLoad,
    Yearpicker,
    Checkbox,
    Combo,
} from '../components/formComponents/fieldComponents';

import * as fieldTypes from './fieldTypes';

export const fieldConfig = {
    [fieldTypes.COMMON_STR]: Input,
    [fieldTypes.COMMON_NUMBER]: InputNumber,
    [fieldTypes.DATE]: Datepicker,
    [fieldTypes.EMAIL_STR]: Input,
    [fieldTypes.PHONE_STR]: InputPhone,
    [fieldTypes.SIMPLE_COMBO]: Combo,
    [fieldTypes.SEARCH_COMBO]: SearchSelectWithLoad,
    [fieldTypes.OTHER_COMBO]: OtherSelectWithLoad,
    [fieldTypes.SINGLE_SELECTION]: OPD,
    [fieldTypes.MULTIPLE_SELECTION]: CheckboxGroupWithLoad,
    [fieldTypes.MULTIPLE_SELECTION_OTHER]: OtherCheckboxGroupWithLoad,
    [fieldTypes.CUSTOM_COMBO]: DesiredPosition,
    [fieldTypes.MONTH]: Monthpicker,
    [fieldTypes.YEAR]: Yearpicker,
    [fieldTypes.FILE]: FileLoader,
    [fieldTypes.CHECKBOX]: Checkbox,
};

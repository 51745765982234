import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import withFieldWrapper from '../hocs/withFieldWrapper';

class RadioButtons extends Component {
    static propTypes = {
        options: PropTypes.array,
        onChange: PropTypes.func,
        field: PropTypes.object,
        valuePath: PropTypes.string
    };

    static defaultProps = {
        options: [],
        valuePath: 'id'
    };

    getOptions() {
        const { fieldOptions, loadname, options: dictionaryOptions } = this.props;
        const options = loadname ? dictionaryOptions : fieldOptions;

        return options.map((option) => ({ name: loadname ? option.name : option.value, value: option.id }));
    }

    renderOptions() {
        const { valuePath } = this.props;

        return this.getOptions().map(option =>
            <Radio key={option[valuePath]} value={option[valuePath]} className='radio'>
                { option.name }
            </Radio>
        );
    }

    render() {
        const { value, onChange, name } = this.props.input;

        return <Radio.Group
            name={name}
            className='radio-box'
            onChange={onChange}
            value={value}>
            { this.renderOptions() }
        </Radio.Group>;
    }
}

export default withFieldWrapper(RadioButtons);

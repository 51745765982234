import React, { Component } from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import { is } from 'ramda';

import withFieldWrapper from '../hocs/withFieldWrapper';

class Checkbox extends Component {
    componentDidMount() {
        if (this.props.input && !is(Boolean, this.props.input.value)) {
            this.props.input.onChange(false);
        }
    }

    render() {
        const { input : { value, onChange, name } } = this.props.field;

        return (
            <AntdCheckbox
                name={name}
                className='checkbox'
                onChange={onChange}
                checked={!!value}
            >
                {this.props.inputLabel || null}
            </AntdCheckbox>
        );
    }
};

export default withFieldWrapper(Checkbox);

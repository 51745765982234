import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import withSettings from '../../hocs/withSettings';
import { Cover, Container, CoverContent, Button } from './styledComponents';
import Block from '../../Block';
import BotButton from '../BotButton';
import Logo from './Logo';
import IsMobileProvider from '../../contexts/IsMobileContext';

class Header extends Component {
    static defaultProps = {
        hideButton: false
    };

    render() {
        const { settings, data, formUrl, commonSettings, hideButton, location: { search }} = this.props;
        const button = <Button {...settings} hideButton={hideButton}>{ settings.btnText }</Button>;

        return <IsMobileProvider.Consumer>
            { isMobile => <Cover {...settings} isMobile={isMobile}>
                <Container>
                    <CoverContent>
                        { settings.logo && <Logo logo={settings.logo} position={settings.logoPosition} /> }
                        <Block html={data.header} name='header' />
                        { formUrl ? <Link to={{
                            pathname: formUrl,
                            search
                        }}>{ button }</Link> : button }
                        <BotButton settings={commonSettings} />
                    </CoverContent>
                </Container>
            </Cover>}
        </IsMobileProvider.Consumer>;
    }
}

export default withSettings(withRouter(Header), 'header');

import Money from '../../assets/img/money.svg';
import Medicine from '../../assets/img/medicine.svg';
import Office from '../../assets/img/office.svg';
import Coffee from '../../assets/img/coffee.svg';
import Learn from '../../assets/img/learn.svg';
import Up from '../../assets/img/up.svg';
import Team from '../../assets/img/team.svg';
import Shield from '../../assets/img/shield.svg';
import Call from '../../assets/img/call.svg';
import Card from '../../assets/img/card.svg';
import Sale from '../../assets/img/sale.svg';
import BlueLogo from '../../assets/img/defaultLogo.svg';

export const BLUE_HEADER_HTML = `
    <h4 class="ql-align-center">
        <span style="color: #0a2896;">Построить карьеру в одной из крупнейших компаний России? <br/> Это проще, чем ты думаешь.</span>
    </h4>
`;

export const BLUE_DESCRIPTION_HTML = `
    <p>
        <span style="color: #fff;">Наша компания - один из лидеров финансового рынка России. Банк обслуживает физических лиц, индивидуальных предпринимателей, предприятия малого и среднего бизнеса. Миллионы клиентов ценят в нас надежность, передовые технологии и качественный сервис.</span>
    </p>
    <p>
        <span style="color: #fff;">Мы приглашаем на работу специалистов Контактного центра в Барнауле, в Москве</span>
    </p>
`;

export const BLUE_DESCRIPTION_HEADER_HTML = `
    <h1>
        <span style="color: #fff;">Сделай шаг<br />навстречу<br />мечте!</span>
    </h1>
`;

export const BLUE_ABLITIES_HTML_1 = `
    <p class="ql-align-center">
        <span style="color: #0a2896;">Стабильный оклад и ежемесячные премии</span>
    </p>
`;

export const BLUE_ABLITIES_HTML_2 = `
    <p class="ql-align-center">
        <span style="color: #0a2896;">ДМС</span>
    </p>
`;

export const BLUE_ABLITIES_HTML_3 = `
    <p class="ql-align-center">
        <span style="color: #0a2896;">Современный офис</span>
    </p>
`;

export const BLUE_ABLITIES_HTML_4 = `
    <p class="ql-align-center">
        <span style="color: #0a2896;">Уютное кафе для завтраков и обедов</span>
    </p>
`;

export const BLUE_ABLITIES_HTML_5 = `
    <p class="ql-align-center">
        <span style="color: #0a2896;">Обучение в корпоративном университете компании</span>
    </p>
`;

export const BLUE_ABLITIES_HTML_6 = `
    <p class="ql-align-center">
        <span style="color: #0a2896;">Широкие возможности для карьерного и профессионального роста</span>
    </p>
`;

export const BLUE_ABLITIES_HTML_7 = `
    <p class="ql-align-center">
        <span style="color: #0a2896;">Дружный молодой коллектив</span>
    </p>
`;

export const BLUE_ABLITIES_HTML_8 = `
    <p class="ql-align-center">
        <span style="color: #0a2896;">Конкурсы и соревнования для сотрудников</span>
    </p>
`;

export const BLUE_OPTION_HTML_1 = `
    <p class="ql-align-center">
        <span style="color: #0a2896;">Рассказывать клиентам о продуктах и услугах компании</span>
    </p>
`;

export const BLUE_OPTION_HTML_2 = `
    <p class="ql-align-center">
        <span style="color: #0a2896;">Консультировать по вопросам, связанным с перевыпуском карт, погашением частичным/досрочным кредитов и другими операциями</span>
    </p>
`;

export const BLUE_OPTION_HTML_3 = `
    <p class="ql-align-center">
        <span style="color: #0a2896;">Предлагать продукты или услуги в дополнение к тем, которыми уже пользуется клиент</span>
    </p>
`;

export const BLUE_CONTENT_HEADER_1 = `
    <h3>
        <span style="color: #0a2896;">Работа в Контактном центре нашей компании — это:</span>
    </h3>
`;

export const BLUE_CONTENT_HEADER_2 = `
    <h1>
        <span style="color: #eb0029;">Стань частью<br />дружной<br />команды нашей компании</span>
    </h1>
`;

export const BLUE_CONTENT_HEADER_3 = `
    <h3>
        <span style="color: #0a2896;">Тебе предстоит:</span>
    </h3>
`;

export const BLUE_CONTENT_DESCRIPTION_1 = `
    <h6>
        <span style="color: #0a2896;">Ты молод и мечтаешь о работе в престижной компании со стабильным доходом?</span>
    </h6>
    <h6>
        <span style="color: #0a2896;">Ты хочешь помогать людям и умеешь найти подход к любому собеседнику?</span>
    </h6>
    <h6>
        <span style="color: #0a2896;">У тебя нет опыта работы в финансах, но ты трудолюбив и полон энтузиазма?</span>
    </h6>
`;

export const BLUE_CONTENT_DESCRIPTION_2 = `
    <p>
        <span style="color: #eb0029;">Для тебя будет организовано специальное обучение, где ты изучишь структуру, процессы, а также продукты и услуги нашей компании. После этого ты сможешь консультировать клиентов даже по самым сложным вопросам.</span>
    </p>
`;

export const BLUE_FOOTER_TEXT_1 = `
    <h1>
        <span style="color: #fff">Готов? Начни свою карьеру в нашей компании прямо сейчас!</span>
    </h1>
`;

export const BLUE_FOOTER_TEXT_2 = `
    <h6>
        <span style="color: #fff">Остались вопросы?</span>
    </h6>
    <h6>
        <span style="color: #fff">Звони, мы с радостью ответим:<br />+7 (999) 777-00-11</span>
    </h6>
`;

export default {
    header: BLUE_HEADER_HTML,
    description: {
        header: BLUE_DESCRIPTION_HEADER_HTML,
        text: BLUE_DESCRIPTION_HTML,
        logo: BlueLogo
    },
    content: {
        abilities: {
            header: BLUE_CONTENT_HEADER_1,
            options: [
                [
                    { html: BLUE_ABLITIES_HTML_1, image: Money },
                    { html: BLUE_ABLITIES_HTML_2, image: Medicine },
                    { html: BLUE_ABLITIES_HTML_3, image: Office },
                    { html: BLUE_ABLITIES_HTML_4, image: Coffee }
                ],
                [
                    { html: BLUE_ABLITIES_HTML_5, image: Learn },
                    { html: BLUE_ABLITIES_HTML_6, image: Up },
                    { html: BLUE_ABLITIES_HTML_7, image: Team },
                    { html: BLUE_ABLITIES_HTML_8, image: Shield }
                ]
            ]
        },
        description: {
            header: BLUE_CONTENT_HEADER_2,
            text: BLUE_CONTENT_DESCRIPTION_1
        },
        duties: {
            header: BLUE_CONTENT_HEADER_3,
            options: [
                { html: BLUE_OPTION_HTML_1, image: Call },
                { html: BLUE_OPTION_HTML_2, image: Card },
                { html: BLUE_OPTION_HTML_3, image: Sale }
            ],
            text: BLUE_CONTENT_DESCRIPTION_2
        }
    },
    footer: {
        header: BLUE_FOOTER_TEXT_1,
        text: BLUE_FOOTER_TEXT_2
    }
};

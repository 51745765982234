import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import MaskedInput from 'react-text-mask';
import cx from 'classnames';
import { isNil, path, contains, prop } from 'ramda';

import { set, format as dateFnsFormat, getDaysInMonth } from 'date-fns'

import withFieldWrapper from '../hocs/withFieldWrapper';
import { DAY_MASK, YEAR_MASK } from '../../constants/masks';
import MONTHS from '../../constants/months';

class DatepickerComponent extends Component {
    static propTypes = {
        input: PropTypes.object
    };

    state = {
        day: undefined,
        month: undefined,
        year: undefined
    };

    onChange = (day, month, year) => {
        const format = path(['options', 'format'], this.props);

        if (format) {
            if (format === 'dd.MM.y') {
                this.props.input.onChange(
                    day && !isNil(month) && year ? (
                        dateFnsFormat(set(new Date(), { year, month, date: day }), 'dd.MM.yyyy')
                    ) : undefined
                );
            } else if (format === 'MM.y') {
                this.props.input.onChange(
                    year && !isNil(month) ? (
                        dateFnsFormat(set(new Date(), { year, month }), 'MM.yyyy')
                    ) : undefined);
            } else if (format === 'y') {
                this.props.input.onChange(year ? (
                    dateFnsFormat(set(new Date(), { year }), 'yyyy')
                ) : undefined);
            }
        } else {
            this.props.input.onChange(
                day && !isNil(month) && year ?
                dateFnsFormat(set(new Date(), { year, month, date: day }), 'yyyyMMdd') :
                undefined
            );
        }
    }

    onChangeDay = e => {
        const { month, year } = this.state;
        const day = e.target.value;

        this.setState({ day });
        this.onChange(day, month, year);
    }

    onChangeMonth = month => {
        const { day: current, year } = this.state;

        const day = month && !year && getDaysInMonth(set(new Date(), { month })) < current ? getDaysInMonth(set(new Date(), { month })) :
            month && year && getDaysInMonth(set(new Date(), { month, year })) < current ? getDaysInMonth(set(new Date(), { month, year })) :
            current;

        this.setState({ month, day });
        this.onChange(day, month, year);
    }

    onChangeYear = e => {
        const { day, month } = this.state;
        const year = e.target.value;

        this.setState({ year });
        this.onChange(day, month, year);
    }

    onBlurDay = () => {
        const { day: current, month, year } = this.state;
        const day = !month && current > 31 ? 31 :
            month && !year && getDaysInMonth(set(new Date(), { month })) < current ? getDaysInMonth(set(new Date(), { month })) :
            month && year && getDaysInMonth(set(new Date(), { month, year })) < current ? getDaysInMonth(set(new Date(), { month, year })) :
            current;

        this.setState({ day });
        this.onChange(day, month, year);
    }

    onBlurYear = () => {
        const { day: current, month, year } = this.state;

        if (month && year) {
            const daysInMonth = getDaysInMonth(set(new Date(), { month: month + 1, year }));

            if (daysInMonth < current) {
                this.setState({ day: daysInMonth });
                this.onChange(daysInMonth, month, year);
            }
        }
    }

    render() {
        const { error, options } = this.props;
        const { day, month, year } = this.state;
        const format = prop('format', options);
        const showDay = format ? contains('dd', format) : true;
        const showMonth = format ? contains('MM', format) : true;
        const showYear = format ? contains('y', format) : true;

        return <div className='datepicker'>
            { showDay && (
                <MaskedInput
                    className={cx('ant-input form-control day', { error: error && !day })}
                    value={day}
                    onChange={this.onChangeDay}
                    onBlur={this.onBlurDay}
                    mask={DAY_MASK}
                    placeholderChar={'\u2000'}
                    keepCharPositions={false}
                    guide={true}
                />
            )}
            { showMonth && (
                <Select
                    className={cx('form-control month', { error: error && isNil(month) })}
                    value={month}
                    onChange={this.onChangeMonth}>
                    { MONTHS.map((month, index) =>
                        <Select.Option key={`month-${index}`} value={index}>
                            { month }
                        </Select.Option>
                    )}
                </Select>
            )}
            { showYear && (
                <MaskedInput
                    className={cx('ant-input form-control year', { error: error && !year })}
                    value={year}
                    onChange={this.onChangeYear}
                    onBlur={this.onBlurYear}
                    mask={YEAR_MASK}
                    placeholderChar={'\u2000'}
                    keepCharPositions={false}
                    guide={true}
                />
            )}
        </div>;
    }
}

export default withFieldWrapper(DatepickerComponent);

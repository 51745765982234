import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'ramda';

import Block from '../Block';
import withImageLoad from '../hocs/withImageLoad';
import withSettings from '../hocs/withSettings';

const StyledContainer = styled.div`
    background-color: ${prop('background')} !important;
`;

const Image = withImageLoad(({ url }) =>
    <div className='icon-box' style={{ backgroundImage: url ? `url(${url})` : null }} />
);

class Options extends Component {
    static propTypes = {
        settings: PropTypes.object,
        data: PropTypes.object
    };

    render() {
        const { settings, data } = this.props;

        return <div className='content'>
            <StyledContainer className='block-1 bg-grey' {...settings}>
                <div className='wrap-block'>
                    <div className='row'>
                        { data.options.map((option, index) =>
                            <div key={`description-option-${index}`} className={`ic-${index + 1}`}>
                                <Image url={option.image} name={`options.${index}.image`} />
                                <Block html={option.html} name={`options.${index}.html`} />
                            </div>
                        )}
                    </div>
                </div>
            </StyledContainer>
        </div>;
    }
}

export default withSettings(Options, 'options');

import React, { Component, Fragment } from 'react';
import { Spin } from 'antd';
import { compose, prop, toPairs, equals, path, is, isEmpty, not, find, toLower, pathOr, dropLast, filter, isNil, startsWith, split, mapObjIndexed } from 'ramda';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { withAsyncActions } from 'react-async-client';
import createDecorator from 'final-form-focus';
import qs from 'qs';

import { postVacancy } from '../actions/asyncActions';
import { getFieldName, notEmpty } from '../helpers/fieldHelpers';
import { getValidator } from '../utils/validation';
import { fieldConfig } from '../constants/fieldConfig';
import CommonHeader from './landingParts/Header';
import AncorHeader from './landingParts/ancor/Header';
import LimeHeader from './landingParts/lime/Header';
import getLandingData from '../utils/getLandingData';
import StyledContainer from './StyledContainer';
import { StyledButtonContainer } from './landingParts/StyledButton';
import { SUCCESS_LINK } from '../constants/urls';
import DocumentHead from './DocumentHead';
import ReCaptcha from './formComponents/ReCaptcha';

const focusOnErrors = createDecorator();

class Form extends Component {
    getInitialValues = () => {
        const { getVacancy: { data }, params, history } = this.props;
        const getParams = qs.parse(pathOr('', ['location', 'search'], history).replace('?', ''));
        const vacancyId = path(['vacancyId'], params)
        const desiredPosition = path(['vacancy'], getParams);

        if (!vacancyId && desiredPosition && data.desiredPosition) {
            const position = path(
                ['name'],
                find(({ name }) => equals(toLower(name), toLower(desiredPosition)), data.desiredPosition)
            );

            if (position) {
                return {
                    'GRADUATEINFO#DESIRED_POSITION_STR': position
                };
            }
        }

        return null;
    }

    getRequestValue = (value) => {
        if (Array.isArray(value)) {
            const arrayWithoutNull = filter((arrayValue => !!arrayValue), value);

            return arrayWithoutNull.map((item) => {
                const requestValue = item.requestValue;

                if (requestValue) {
                    return requestValue;
                } else {
                    return is(Object, item) ? mapObjIndexed((value) => {
                        return this.getRequestValue(value);
                    }, item) : item;
                }
            });
        }

        if (is(Boolean, value)) {
            return !!value;
        }

        return value ? is(Object, value) ? value.requestValue : value.toString() : value;
    }

    getHeader = () => {
        const { template } = getLandingData(this.props.getVacancy.data);

        switch (template) {
            case 'ancor':
                return AncorHeader;
            case 'lime':
                return LimeHeader;
            default:
                return CommonHeader;
        }
    }

    getCompositeInitialValues = (fields) => {
        let initialValue = {};

        fields.forEach((field) => {
            if (field.fieldType === 'file') {
                const isMultiple = path(['options', 'isMultiple'], field);
                initialValue[field.xmlName] = isMultiple ? [null] : null;
            } else {
                initialValue[field.xmlName] = null;
            }
        });

        return initialValue;
    }

    renderCompositeFields = (item, form, vacancyId) => {
        const { fields: itemFields, fieldType } = item;
        const initialValue = this.getCompositeInitialValues(itemFields);
        const isMultiple = split('_', fieldType)[1] === 'multiple';

        return (
            <FieldArray
                name={getFieldName(item)}
                initialValue={[initialValue]}
            >
                {({ fields }) => {
                    return (
                        <Fragment>
                            <div className='field-group-label composite-group-label'>{ item.nameForWeb }</div>
                            { fields.map((name, fieldIndex) => (
                                <div className='composte-field' key={item.xmlName}>
                                    { itemFields.map((field) => {
                                        const fieldName = `${name}.${field.xmlName}`;

                                        return this.renderField(field, form, vacancyId, fieldName);
                                    })}
                                    { fields.value.length > 1 && (
                                        <StyledButtonContainer>
                                            <button
                                                type='button'
                                                className='btn composite-remove-button'
                                                onClick={() => fields.remove(fieldIndex)}
                                            >
                                                Удалить
                                            </button>
                                        </StyledButtonContainer>
                                    )}
                                </div>
                            ))}
                            { isMultiple && (
                                <StyledButtonContainer>
                                    <button
                                        type='button'
                                        className='btn composite-add-button'
                                        onClick={() => fields.push(initialValue)}
                                    >
                                        Добавить
                                    </button>
                                </StyledButtonContainer>
                            )}
                        </Fragment>
                    );
                }}
            </FieldArray>
        );
    }

    renderField = (item, form, vacancyId, fieldName) => {
        const isMultiple = path(['options', 'isMultiple'], item);
        const name = fieldName ? fieldName : getFieldName(item);

        if (item.fieldType === 'file' && isMultiple) {
            return (
                <FieldArray
                    name={name}
                    initialValue={fieldName ? undefined : [null]}
                    validate={(values) => {
                        const validators = getValidator(item);

                        if (Array.isArray(values)) {
                            const errors = values.map(value => validators(value));

                            if (values.length > 1) {
                                return dropLast(1, errors);
                            } else {
                                return errors;
                            }
                        } else {
                            return validators(values);
                        }
                    }}
                >
                    {({ fields }) => (
                        <Fragment>
                            <div className='field-group-label'>{ item.nameForWeb }</div>
                            {fields.map((name, index) => {
                                return this.getField(item, name, form, {
                                    vacancyId: vacancyId,
                                    fields,
                                    fileIndex: index,
                                    key: index,
                                    label: null,
                                });
                            })}
                        </Fragment>
                    )}
                </FieldArray>
            );
        } else {
            return this.getField(item, name, form, {
                vacancyId: vacancyId,
            });
        }
    }

    getField = (item, name, form, props) => (
        fieldConfig[item.fieldType] && (
            <Field
                key={name}
                name={name}
                label={item.nameForWeb}
                required={item.isRequired}
                loadname={item.vocName}
                customFieldValue={getFieldName(item, true)}
                component={fieldConfig[item.fieldType]}
                validate={getValidator(item)}
                change={form.change}
                options={path(['options'], item)}
                fieldOptions={path(['options', 'choices'], item)}
                {...props}
            />
        )
    )

    getFieldName = (fieldList, field) => {
        let fieldName = '';

        fieldList.forEach(({ nameForWeb, xmlName, fields }) => {
            if (!fieldName) {
                if (field === xmlName) {
                    fieldName = nameForWeb;
                } else if (!isNil(fields)) {
                    fieldName = this.getFieldName(fields, field);
                }
            }
        });

        return fieldName;
    }

    getServerErrorList = () => {
        const { postVacancy: { meta }, getVacancy: { data: { fieldList } } } = this.props;
        const errors = path(['error', 'data', 'errors'], meta) || [];

        return errors.map(({ message, field }) => {
            const name = this.getFieldName(fieldList, field);

            return (
                <div>{`${ name ? `${name} - `: ''} ${message}`}</div>
            );
        });
    }

    render() {
        const { postVacancy, postVacancy: { meta }, getVacancy: { data: { id, fieldList, vacanciesFieldList }}, params } = this.props;
        const { template, data } = getLandingData(this.props.getVacancy.data);
        const btnSettings = template === 'blue' ? data.settings.description : data.settings.header;
        const Header = this.getHeader();
        const fields = pathOr(fieldList, [params.vacancyId], vacanciesFieldList);
        const vacancyId = params.vacancyId || id;
        const captchaRequired = path(['company', 'companySettings', 'captcha', 'landings'], this.props.getVacancy.data);

        const onSubmit = (values) => {
            const fields = toPairs(values).map(([ key, value ]) => {
                const [ tableName, name ] = key.split('#');
                const requestValue = this.getRequestValue(value);

                return ({ tableName, name, value: requestValue });
            }).filter(compose(not, isEmpty, prop('value')));

            postVacancy.dispatch({
                id: vacancyId,
                fields
            });
        };

        return (
            <StyledContainer
                className={`wrap form ${template}`}
                background={data.settings.header.mainBackground}
                template={template}
                styles={path(['settings', 'common', 'styles'], data)}>
                <DocumentHead
                    title={pathOr('Анкета кандидата', ['settings', 'common', 'pageTitle'], data)}
                    script={path(['settings', 'common', 'script'], data)} />
                <Header
                    template={template}
                    data={data.description}
                    settings={data.settings}
                    hideButton />
                <div className='content body-block'>
                    <div className='block-1'>
                        <div className='wrap-block'>
                            <h2 id='form'>{ pathOr('Анкета кандидата', ['settings', 'common', 'formTitle'], data) }</h2>
                            <FinalForm
                                onSubmit={onSubmit}
                                decorators={[ focusOnErrors ]}
                                initialValues={this.getInitialValues()}
                                subscription={{ submitting: true, submitFailed: true, error: true }}
                                mutators={{...arrayMutators}}
                                render={({ handleSubmit, form }) => (
                                    <form onSubmit={handleSubmit}>
                                        { notEmpty(fields) && fields.map((item, i) => {
                                                if (startsWith('composite', item.fieldType)) {
                                                    return (
                                                        <div key={i}>
                                                            { this.renderCompositeFields(item, form, vacancyId) }
                                                        </div>
                                                    );
                                                } else {
                                                    return (
                                                        <div key={i}>
                                                            { this.renderField(item, form, vacancyId) }
                                                        </div>
                                                    );
                                                }
                                            }
                                        )}
                                        { !!captchaRequired &&
                                            <Field
                                                name='_captcha'
                                                component={ReCaptcha}
                                                validate={value => value ? undefined : 'Необходимо пройти проверку'} />
                                        }
                                        <div className='control'>
                                            <StyledButtonContainer {...btnSettings}>
                                                <button type='submit' className='btn' disabled={meta.pending}>
                                                    { meta.pending && <Spin /> } Отправить
                                                </button>
                                            </StyledButtonContainer>
                                            { meta.error && (
                                                <div className='error-message'>
                                                    { meta.error.message || 'Неизвестная ошибка сервера' }
                                                    { this.getServerErrorList() }
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </StyledContainer>
        );
    }
};

export default withAsyncActions({
    postVacancy: postVacancy.withSuccessHandler(({ history, id, vacancyId }, action) => {
        window.onSubmit && window.onSubmit(path(['requestAction', 'payload'], action));
        history.push(SUCCESS_LINK.stringify({ id, vacancyId }));
    })
})(Form);

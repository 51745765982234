import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { last, split } from 'ramda';

import { b64toBlob } from '../../../helpers/fileHelpers';
import { StyledModal, ErrorContentWrapper } from './StyledComponents';
import Image from './Image';

export default class ImageModal extends Component {
    static propTypes = {
        visible: PropTypes.bool,
        closeModal: PropTypes.func,
        available: PropTypes.bool,
        onSave: PropTypes.func,
        uploadButton: PropTypes.node
    };

    onSave = data => {
        const base64 = last(split(',', data));
        const blobImage = b64toBlob(base64, 'image/jpeg');
        this.props.onSave(data, blobImage);
        this.props.closeModal();
    }

    render() {
        const { width, visible, closeModal, available, uploadButton } = this.props;

        return <StyledModal
            width={width}
            visible={visible}
            onCancel={closeModal}
            title='Изображение'
            footer={null}
            destroyOnClose
        >
            { available ?
                <Image onSave={this.onSave} /> :
                <ErrorContentWrapper>
                    <Alert
                        type='error'
                        message='Доступ к камере заблокирован. Разрешите доступ к камере в настройках браузера' />
                    { uploadButton }
                </ErrorContentWrapper> }
        </StyledModal>;
    }
}

import isEmail from 'validator/lib/isEmail';
import { trim, prop } from 'ramda';
import { parse, isValid } from 'date-fns';

import { PHONE_VAIDATOR } from '../constants/regexp';
import { EMAIL_STR, YEAR, PHONE_STR, DATE, FILE } from '../constants/fieldTypes';
import { dateFnsFormats } from '../constants/formats';

const required = (value) => value ? undefined : 'Это поле обязательно';
const fileRequired = (value) => value ? prop('requestValue', value) ? undefined : 'Дождитесь загрузки файла' : undefined;
const email = (value = '') => !value || isEmail(value) ? undefined : 'Неверный email';
const year = (value = '') => !value || trim(value).length === 4 ? undefined : 'Неверный формат';
const phone = (value = '') => !value || value.match(PHONE_VAIDATOR) ? undefined : 'Введите телефон в формате: +79123456789 ';
const date = (value = '', options = {}) => {
    const format = options.format;
    const dateFnsFormat = dateFnsFormats[format];

    if (format && value) {
        const date = parse('value', dateFnsFormat, new Date());
        const isValidDate = isValid(date);

        if (isValidDate) {
            return undefined;
        } else {
            return 'Неверный формат даты';
        }
    } else {
        return undefined;
    }
};

const composeValidators = (validators, options) => (value) =>
    validators.reduce((error, validator) => error || validator(value, options), undefined);

const VALIDATION_TYPES = {
    [FILE]: [fileRequired],
    [PHONE_STR]: [phone],
    [EMAIL_STR]: [email],
    [YEAR]: [year],
    [DATE]: [date]
};

export const getValidator = ({ isRequired, fieldType, options }) => {
    const validatorsArray = VALIDATION_TYPES[fieldType] || [];

    return isRequired ? composeValidators([required, ...validatorsArray], options) : composeValidators(validatorsArray, options);
};

import React, { Component } from 'react';
import { CameraOutlined } from '@ant-design/icons';

import ImageModal from './ImageModal';
import Loader from './Loader';

export default class ImageLoader extends Component {
    getPreview = () => {
        const { value } = this.props.input;

        return value ? (
            <a href={value.requestValue} target='_blank' rel='noopener noreferrer'>
                <img width={200} src={value.base64} alt={value.name} />
            </a>
        ) : '';
    }

    render() {
        return <Loader
            {...this.props}
            preview={this.getPreview()}
            accept='image/*'
            capture='camera'
            icon={<CameraOutlined />}
            Modal={ImageModal}
            recordLabel='Сделать фото'
            uploadLabel='Загрузить изображение'
            media={{ video: true }} />;
    }
}

import React, { Component } from 'react';
import { prop, path, find, pathOr, isNil, propEq } from 'ramda';
import { asyncConnect } from 'react-async-client';
import Form from '@experium/findy-form';
import { Link } from 'react-router-dom';

import { postFindyVacancy } from '../actions/asyncActions';
import { getUtmFromSearch, getUtmTool } from '../utils/utm';
import CommonHeader from './landingParts/Header';
import AncorHeader from './landingParts/ancor/Header';
import LimeHeader from './landingParts/lime/Header';
import getLandingData from '../utils/getLandingData';
import StyledContainer from './StyledContainer';
import { SUCCESS_LINK, POST_FINDY_FILE } from '../constants/urls';
import DocumentHead from './DocumentHead';
import { StyledButtonContainer } from './landingParts/StyledButton';
import { FINDY_ERRORS } from '../constants/errors';

class FindyForm extends Component {
    getHeader = () => {
        const { template } = getLandingData(this.props.getVacancy.data);

        switch (template) {
            case 'ancor':
                return AncorHeader;
            case 'lime':
                return LimeHeader;
            default:
                return CommonHeader;
        }
    }

    getFieldName = (fieldList, field) => {
        let fieldName = '';

        fieldList.forEach(({ nameForWeb, xmlName, fields }) => {
            if (!fieldName) {
                if (field === xmlName) {
                    fieldName = nameForWeb;
                } else if (!isNil(fields)) {
                    fieldName = this.getFieldName(fields, field);
                }
            }
        });

        return fieldName;
    }

    getServerErrorList = () => {
        const { postFindyVacancy: { meta }, getVacancy: { data } } = this.props;
        const errors = path(['error', 'data', 'errors'], meta) || [];
        const fields = prop('fields', data) || [];

        return errors.map(({ message, field }, index) => {
            const name = prop('label', find(propEq('field', field), fields));

            return (
                <div key={`${field}-${index}`}>{`${ name ? `${name} - `: ''} ${message}`}</div>
            );
        });
    }

    getFileUrl = (id) => {
        if (window.fileUrl) {
            return `${window.fileUrl}/api/file/${id}`
        } else {
            return `${window.location.origin}/api/file/${id}`;
        }
    }

    render() {
        const { postFindyVacancy, postFindyVacancy: { meta }, getVacancy: { data : vacancy = {}, meta : vacancyMeta }, params, location } = this.props;
        const { id, company, fields, vacancies, options } = vacancy;
        const { template, data } = getLandingData(this.props.getVacancy.data);
        const Header = this.getHeader();
        const btnSettings = template === 'blue' ? data.settings.description : data.settings.header;
        const vacancyId = params.vacancyId || id;
        const opd = path(['pdaSettings', 'text'], company);
        const allowFileExtensions = path(['companySettings', 'allowFileExtensions'], company);
        const captcha = path(['companySettings', 'captcha', 'landings'], company);

        const onSubmit = (values) => {
            postFindyVacancy.dispatch({
                id: vacancyId,
                ...values,
                _utm: getUtmFromSearch(location.search),
                _tool: getUtmTool(params.vacancyId),
            });
        };

        return (
            <StyledContainer
                className={`wrap form ${template}`}
                background={data.settings.header.mainBackground}
                template={template}
                styles={path(['settings', 'common', 'styles'], data)}>
                <DocumentHead
                    title={pathOr('Анкета кандидата', ['settings', 'common', 'pageTitle'], data)}
                    script={path(['settings', 'common', 'script'], data)} />
                <Header
                    template={template}
                    data={data.description}
                    settings={data.settings}
                    hideButton />
                <div className='content body-block form-block'>
                    <div className='block-1'>
                        { vacancyMeta.success ? (
                            vacancies ? (
                                <div className='wrap-block'>
                                    <h2 id='form'>{btnSettings.btnText || 'Заполни анкету кандидата'}</h2>
                                    {vacancies && vacancies.length ? (
                                        vacancies.map(item => (
                                            <Link key={item.id} className='btn-link' to={{ pathname: `/${params.id}/form/${item.id}`, search: location.search }}>
                                                <StyledButtonContainer {...btnSettings}>
                                                    <button type='submit' className='btn vacancy-btn-link'>{item.title}</button>
                                                </StyledButtonContainer>
                                            </Link>
                                        ))
                                    ) : null}
                                </div>
                            ) : (
                                <div className='wrap-block'>
                                    <h2 id='form'>{ pathOr('Анкета кандидата', ['settings', 'common', 'formTitle'], data) }</h2>
                                    <Form
                                        fields={fields}
                                        onSubmit={onSubmit}
                                        opd={opd}
                                        postFileUrl={vacancyId && POST_FINDY_FILE.stringify({ vacancyId })}
                                        getFileUrl={this.getFileUrl}
                                        allowFileExtensions={allowFileExtensions}
                                        serverErrors={meta.error}
                                        submitting={meta.pending}
                                        captcha={captcha}
                                        captchaOptions={options}
                                        company={company.id}
                                        opdSettings={pathOr({}, ['pdaSettings'], company)}

                                    />
                                    { meta.error && (
                                        <div className='error-message'>
                                            <div dangerouslySetInnerHTML={{ __html: FINDY_ERRORS[path(['data', 'message'], meta.error)] || meta.error.message || 'Неизвестная ошибка сервера' }} />
                                            { this.getServerErrorList() }
                                        </div>
                                    )}
                                </div>
                            )
                        ) : null }
                    </div>
                </div>
            </StyledContainer>
        );
    }
};

export default asyncConnect({
    postFindyVacancy: postFindyVacancy.withSuccessHandler(({ history, id, vacancy }, action) => {
        window.onSubmit && window.onSubmit(path(['requestAction', 'payload'], action));
        history.push(SUCCESS_LINK.stringify({ id, vacancyId: vacancy }));
    })
})(FindyForm);

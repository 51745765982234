import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import { any, last, pathEq, sum } from 'ramda';
import { Field } from 'react-final-form';

import { fieldConfig } from '../../constants/fieldConfig';
import { COMMON_STR } from '../../constants/fieldTypes';
import withFieldWrapper from '../hocs/withFieldWrapper';
import { getValidator }  from '../../utils/validation';
import { getOtherId }  from '../../utils/fields';

class CheckboxGroupComponent extends Component {
    static propTypes = {
        options: PropTypes.array,
        onChange: PropTypes.func,
        field: PropTypes.object,
        other: PropTypes.bool,
        customFieldValue: PropTypes.string,
        required: PropTypes.bool,
        noSum: PropTypes.bool,
        change: PropTypes.func,
    };

    static defaultProps = {
        options: [],
    };

    state = {
        value: null
    }

    onChange = value => {
        const { options, other, input, change, customFieldValue, noSum } = this.props;

        this.setState({ value });
        input.onChange(noSum ? value : sum(value));

        if (other && !pathEq(['id'], value, last(options))) {
            change(customFieldValue, undefined);
        }
    }

    getOptions() {
        const { fieldOptions, loadname, options: dictionaryOptions } = this.props;
        const options = loadname ? dictionaryOptions : fieldOptions;

        return options.map((option) => ({
            label: loadname ? option.name : option.value,
            value: option.id || ''
        }));
    }

    render() {
        const { input: { name }, customFieldValue, other } = this.props;
        const options = this.getOptions();
        const otherOption = other && getOtherId(options, 'label', 'value');

        return <div>
            <Checkbox.Group
                name={name}
                className='checkbox-group'
                onChange={this.onChange}
                options={options}
                value={this.state.value}
            />

            { other && (any(value => value === otherOption, this.state.value || [])) ? (
                <Field
                    name={customFieldValue}
                    component={fieldConfig[COMMON_STR]}
                    required
                    validate={getValidator({ isRequired: true })} />
            ) : null}
        </div>;
    }
}

const CheckboxGroup =  withFieldWrapper(CheckboxGroupComponent);
export const OtherCheckboxGroup = props => <CheckboxGroup other={true} {...props}  />;

export default CheckboxGroup;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'ramda';

import Block from '../../Block';
import withSettings from '../../hocs/withSettings';
import withImageLoad from '../../hocs/withImageLoad';

const StyledContainer = styled.div`
    background-color: ${prop('background')} !important;
`;

const Image = withImageLoad(({ url }) =>
    <div className='icon-box' style={{ backgroundImage: url ? `url(${url})` : null }} />
);

class Main extends Component {
    static propTypes = {
        settings: PropTypes.object,
        data: PropTypes.object
    };

    render() {
        const { settings, data } = this.props;

        return <StyledContainer className='block-1 bg-grey' {...settings}>
            { settings.mode === 'text' ?
                <div className='block-1-description'>
                    <Block html={data.description} name='description' />
                </div> :
                <div className='row'>
                    { data.options.map((option, index) =>
                        <div key={`description-option-${index}`} className={`ic-${index + 1}`}>
                            <Image url={option.image} name={`options.${index}.image`} />
                            <Block html={option.html} name={`options.${index}.html`} />
                        </div>
                    )}
                </div>
            }
        </StyledContainer>;
    }
}

export default withSettings(Main, 'main');

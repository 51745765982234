import styled, { css } from 'styled-components';
import { prop, indexOf } from 'ramda';
import { BULLETS } from '../constants/bullets';

const StyledContainer = styled.div`
    background-color: ${prop('background')};
    ul li::before, .ql-editor ul > li:before {
        content: '\\e90${({ bulletType }) => indexOf(bulletType, BULLETS)}';
        color: ${prop('bulletColor')};
    }
    ${props => props.template === 'lime' && limeHeadersStyles}
    ${prop('styles')}
`;

const limeHeadersStyles = css`
    h1, h2, h3, h4, h5, h6{
        color: inherit;
    }
    h1{
        font-size: 42px;
    }
    h2{
        font-size: 36px;
    }
    h3{
        font-size: 28px;
    }
    h4{
        font-size: 22px;
    }
    h5{
        font-size: 18px;
    }
    h6{
        font-size: 16px;
    }
    @media(max-width: 48em){
        h1{
            font-size: 32px;
        }
        h2{
            font-size: 28px;
        }
        h3{
            font-size: 22px;
        }
        h4{
            font-size: 28px;
        }
        h5{
            font-size: 16px;
        }
   }
`;

export const StyledBuilderContainer = styled(StyledContainer)`
    ${props => props.template === 'lime' && css`
        padding-bottom: 0;
        .content {
            max-width: 100%;
            margin: 0;
        }
        .ql-toolbar-wrapper{
            color: #333;
        }
    `}
`;

export default StyledContainer;

import React from 'react';
import { find, prop } from 'ramda';
import cx from 'classnames';

import { getBackgroundColorStyle } from '../utils/getStyles';
import DocumentHead from './DocumentHead';

const defaultMessage = 'Ошибка сервера. Попробуйте обновить страницу через некоторое время.';
const defaultStubMessage = 'Вакансия не опубликована';
const notFoundMessage = 'Ой! Страница не найдена';

const getMessage = ({ data: { parts = [] } }) => {
    const stubHtml = prop('data', find(({ type }) => type === 'stub', parts) || {});
    return stubHtml ? <span className='stab' dangerouslySetInnerHTML={{__html: stubHtml }} /> : defaultStubMessage;
};
const getHeaderStyle = ({ data: { pageDescription = {} } }) => getBackgroundColorStyle('.wrap')(pageDescription.backgroundImageColor);

const Error = ({ title, status, message, style, disableReload }) => (
    <div className='wrap error'>
        <DocumentHead title={title || 'Ошибка'} />
        <style dangerouslySetInnerHTML={{__html: style}} />
        <div className='error-block'>
            <h1>{status}</h1>
            <h2 className={cx({ 'with-cursor': !disableReload })} onClick={() => {
                !disableReload && window.location.reload();
            } }>
                {status === 404 ? notFoundMessage : message || defaultMessage}
            </h2>
        </div>
    </div>
);

export const VacancyClosed = ({ action }) => <Error title='Сбор откликов приостановлен' message={getMessage(action)} style={getHeaderStyle(action)} disableReload />;

export const ErrorFromServer = ({ error }) => <Error status={error.status} message={error.message} />;

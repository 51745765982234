const ERROR = {
    'unknown': 'Ошибка сервера',
    '1':       'Внутренняя ошибка базы данных',
    '2':       'Получена неизвестная команда от RESTful сервиса',
    '3':       'Неверный формат данных получен от RESTful сервиса',
    '4':       'Введены некорректные данные',
    '5':       'Нельзя осуществить вход в систему (неверный логин, пароль, отсутствует такой пользователь)',
    '6':       'Сессия не существует или истекла',
    '7':       'Доступ запрещен (нет прав, нет подходящей лицензии)',
    '8':       'Некорректный сервер назначения',
    '9':       'Отсутствует или не найден сервер обновления',
    '10':      'Отсутствует ключ сессии',
    '11':      'Отстутствует регистрация на support сервере',
    '12':      'Нет регистрации на support сервере - логин занят',
    '13':      'Переданные данные были потеряны',
    '14':      'Пользователь уже зарегистрирован в HR сети',
    '15':      'Слишком большое количество пользователей в HR сети',
    '16':      'Сотрудник не зарегистрирован в HR сети',
    '17':      'Неверный ключ аутентификации',
    '18':      'Данные не загружены',
    '19':      'Неизвестный или просроченный ключ аутентификации',
    '20':      'Данный проект тестирования завершен',
    '21':      'Логин уже зарегистрирован',
    '22':      'Неверный пользовательский логин',
    '255':     'Доступ временно заблокирован. Для разблокировки введите верный логин, пароль и символы с картинки'
};

export const FINDY_ERRORS = {
    'Invalid captcha': 'Неверно введена капча',
    'Access to applicant create restricted for current company license': ' ',
};

export default ERROR;

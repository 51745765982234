import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { prop, pathOr, forEach, equals, append, flatten } from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import ClickOutside from 'react-click-outside';

import botIcon from '../../assets/img/bot.ico';
import { BOT_ICONS } from '../../constants/bots';
import { checkLink } from '../../utils/bots';
import { getUtm } from '../../utils/utm';
import EditContext from '../../context/EditContext';
import BotWidget from './BotWidget';

const mainButtonStyle = css`
    width: 50px;
    height: 50px;
    background-image: ${({ disableIcon }) => !disableIcon && `url('${botIcon}')`};
    border-radius: 80%;
    background-color: #fff;
    background-size: 65%;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    box-shadow: 0px 1px 11px -2px rgba(0, 0, 0, 0.18), 0px 9px 12px -7px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: all .3s;
    &:hover {
        box-shadow: 0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0,0,0,.28);
    }
`

const ToggleButton = styled.button`
    ${mainButtonStyle}
`;

const MainLink = styled.a`
    ${mainButtonStyle}

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #fff;
    background-color: ${({ color }) => color || '#fff'};
    &:hover {
        color: #fff;
    }
`;

const BotIcon = styled.span`
    display: inline-block;
    width: 26px;
    height: 26px;
    background-image: url('${prop('icon')}');
    background-size: cover;
    background-position: center;
    margin-bottom: 2px;
`;

const Container = styled.div`
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 4;

    ${({ inHeader }) => inHeader ? css`
        position: relative;
        bottom: 0;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        padding-top: ${({ ancor }) => ancor ? 0 : 15}px;
        .bot-toggle-btn {
            display: none;
        }
        .bot-link {
            opacity: 1;
            transform: scale(1);
            margin: 0 10px;
            margin-top: ${({ ancor }) => ancor ? 5 : 0}px;;
            &:before {
                right: auto;
                top: calc(100% + 10px);
            }
        }
    ` : css`
        footer & {
            display: none;
        }
    `}
`;

const BotLink = styled.a`
    opacity: 0;
    transition: transform .1s ease-in .${prop('indexOut')}s, opacity .1s ease-in .${prop('indexOut')}s, box-shadow .3s;
    background: ${({ color }) => color || '#fff'};
    box-shadow: 0px 3px 11px -2px rgba(0, 0, 0, 0.18), 0px 4px 12px -7px rgba(0, 0, 0, 0.15);
    color: #fff;
    width: 40px;
    height: 40px;
    transform: scale(0);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 10px;
    font-size: 20px;
    position: relative;
    &:hover {
        color: #fff;
        box-shadow: 0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0,0,0,.28);
        &:before {
            opacity: 1;
        }
    }
    &.hover,
    &.fixed {
        opacity: 1;
        transform: scale(1);
        transition: transform .1s ease-out .${prop('indexIn')}s, opacity .1s ease-out .${prop('indexIn')}s, box-shadow .3s;
    }
    &:before {
        content: '${prop('tooltip')}';
        position: absolute;
        background: rgba(0,0,0,.7);
        padding: 0 5px;
        border-radius: 3px;
        font-size: 12px;
        right: calc(100% + 10px);
        white-space: nowrap;
        opacity: 0;
        transition: opacity .3s;
        pointer-events: none;
    }
`;

export default class BotButton extends Component {
    static propTypes = {
        link: PropTypes.string,
        inHeader: PropTypes.bool
    };

    state = {
        bots: [],
        selectedBots: [],
        fixed: false,
        error: false,
    };

    componentDidMount() {
        this.getOptions();
    }

    componentDidUpdate(prev) {
        if (prop('vacancyLink', prev.settings) !== prop('vacancyLink', this.props.settings)) {
            this.getOptions();
        }

        if (!equals(prop('bots', prev.settings), prop('bots', this.props.settings))) {
            this.getSelectedBots();
        }
    }

    getSelectedBots = async () => {
        const links = pathOr([], ['bots'], this.props.settings);

        if (links.length) {
            let selectedBots = [];

            await forEach(async (link) => {
                try {
                    const data = await checkLink(link);
                    selectedBots = append(data, selectedBots);
                } catch(error) {}
            }, links);

            this.setState({ selectedBots: flatten(selectedBots) });
        }
    }

    getOptions() {
        const { vacancyLink } = this.props.settings;

        if (vacancyLink) {
            checkLink(vacancyLink)
                .then(bots => {
                    this.setState({ bots, error: null });
                })
                .catch(error => {
                    this.setState({ bots: [], error });
                });
        }
    }

    getIndexString = index => {
        return (index / 2).toString().replace('.', '');
    }

    openWidget = () => {
        if (window.jobot) {
            window.jobot.toggleWidget();
        }
    }

    isWidget = bot => {
        const { vacancyLinkWidget : isWidget } = this.props.settings;
        return isWidget && bot.type === 'web';
    }

    getLinkParams = bot => edit => {
        const utm = getUtm();

        return this.isWidget(bot) && !edit ? {
            onClick: this.openWidget,
        } : {
            href: !edit ? `${bot.link}&${utm}` : null,
            target: '_blank',
            rel: 'noopener noreferrer',
        };
    }

    renderBotLink = (bot, index) => {
        const { fixed, bots, hover } = this.state;
        const options = BOT_ICONS[bot.type] || {};
        const getParams = this.getLinkParams(bot);

        return <EditContext.Consumer key={`bot-${bot.id}-${index}`}>
            { edit =>
                <BotLink
                    tooltip={bot.username}
                    className={cx('bot-link', { fixed, hover })}
                    color={options.color}
                    indexIn={this.getIndexString(bots.length - 1 - index)}
                    indexOut={this.getIndexString(index)}
                    {...getParams(edit)}
                >
                    { options.icon ?
                        <FontAwesomeIcon icon={options.icon} /> :
                        <BotIcon icon={botIcon} /> }
                    { this.isWidget(bot) && !edit && (
                        <BotWidget bot={bot} />
                    )}
                </BotLink>
            }
        </EditContext.Consumer>;
    }

    toggle = () => this.setState(prev => ({ fixed: !prev.fixed }));

    onMouseEnter = () => this.setState({ hover: true });

    onMouseLeave = () => this.setState({ hover: false });

    renderSingleButton = () => {
        const { headerVacancyLink : inHeader } = this.props.settings;
        const [ bot ] = this.state.bots;
        const options = BOT_ICONS[bot.type] || {};
        const getParams = this.getLinkParams(bot);

        return <Container inHeader={inHeader} ancor={this.props.ancor}>
            <EditContext.Consumer>
                { edit =>
                    <MainLink
                        disableIcon={options.icon}
                        color={options.color}
                        {...getParams(edit)}
                    >
                        { options.icon &&
                            <FontAwesomeIcon icon={options.icon} />
                        }
                        { this.isWidget(bot) && !edit && (
                            <BotWidget bot={bot} />
                        )}
                    </MainLink>
                }
            </EditContext.Consumer>
        </Container>;
    }

    renderErrorButton = () => {
        const { headerVacancyLink : inHeader } = this.props.settings;

        return (
            <Container inHeader={inHeader} ancor={this.props.ancor}>
                <EditContext.Consumer>
                    { edit => edit ? (
                        <MainLink
                            color={'red'}
                            onClick={() => this.getOptions()}
                        />
                    ) : null }
                </EditContext.Consumer>
            </Container>
        );
    }

    render() {
        const { headerVacancyLink : inHeader } = this.props.settings;
        const { bots, selectedBots, error } = this.state;

        if (error) {
            return this.renderErrorButton();
        }

        const items = [...bots, ...selectedBots];

        return !!items.length &&
            ( items.length === 1 ?
                this.renderSingleButton() :
                <ClickOutside onClickOutside={() => this.setState({ fixed: false })}>
                    <Container
                        onMouseLeave={!inHeader ? this.onMouseLeave : null}
                        inHeader={inHeader}
                        ancor={this.props.ancor}
                        many>
                        { items.map(this.renderBotLink) }
                        <ToggleButton
                            className='bot-toggle-btn'
                            onClick={this.toggle}
                            onMouseEnter={this.onMouseEnter} />
                    </Container>
                </ClickOutside>
            );
    }
}

export const BULLETS = [
    'check-circle',
    'check-square',
    'radio_unchecked',
    'radio_checked',
    'check_circle',
    'check_box',
    'check',
    'check-bold',
    'heart',
    'check-circle2'
];

export const COMMON_STR = 'common_str';
export const COMMON_NUMBER = 'common_number';
export const DATE = 'date';
export const EMAIL_STR = 'email_str';
export const PHONE_STR = 'phone_str';
export const SIMPLE_COMBO = 'simple_combo';
export const SEARCH_COMBO = 'search_combo';
export const OTHER_COMBO = 'other_combo';
export const CHECKBOX = 'checkbox';
export const SINGLE_SELECTION = 'single_selection';
export const MULTIPLE_SELECTION = 'multiple_selection';
export const MULTIPLE_SELECTION_OTHER = 'multiple_selection_other';
export const CUSTOM_COMBO = 'custom_combo';
export const YEAR = 'year';
export const MONTH = 'month';
export const FILE = 'file';

export const OTHER = 'Иное';

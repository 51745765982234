import { createAsyncAction, takeOnce } from 'react-async-client';

import { GET_VACANCY, GET_DICTIONARY, POST_VACANCY, POST_FILE, POST_FINDY_VACANCY } from '../constants/actionTypes';
import { getVacancyHandler, getDictionaryHandler, postVacancyHandler, postFileHandler, postFindyVacancyHandler  } from './handlers';
import { takeServerOnce } from '../utils/takeServerOnce';
import vacancy from '../reducers/vacancy';

export const getVacancy = createAsyncAction(GET_VACANCY, getVacancyHandler, {}, takeServerOnce('vacancyId'), vacancy);

export const getDictionary = createAsyncAction(GET_DICTIONARY, getDictionaryHandler, [], takeOnce);

export const postVacancy = createAsyncAction(POST_VACANCY, postVacancyHandler);
export const postFile = createAsyncAction(POST_FILE, postFileHandler);

export const postFindyVacancy = createAsyncAction(POST_FINDY_VACANCY, postFindyVacancyHandler);

import Money from '../../assets/img/money.svg';
import Medicine from '../../assets/img/medicine.svg';
import Office from '../../assets/img/office.svg';
import BlueLogo from '../../assets/img/defaultLogo.svg';

import {
    BLUE_ABLITIES_HTML_1,
    BLUE_ABLITIES_HTML_2,
    BLUE_ABLITIES_HTML_3,
    BLUE_CONTENT_DESCRIPTION_1,
    BLUE_CONTENT_HEADER_2,
    BLUE_DESCRIPTION_HEADER_HTML,
    BLUE_DESCRIPTION_HTML,
    BLUE_FOOTER_TEXT_1,
    BLUE_FOOTER_TEXT_2,
    BLUE_HEADER_HTML
} from './blue';

export const BLUE_OPTIONS_DESCRIPTION_HTML = `
    <p class="ql-align-center">
        <span style="color: rgb(10, 40, 150);">Наша компания - один из лидеров финансового рынка России. Банк обслуживает физических лиц, индивидуальных предпринимателей, предприятия малого и среднего бизнеса. Миллионы клиентов ценят в нас надежность, передовые технологии и качественный сервис.</span>
    </p>
    <p class="ql-align-center">
        <span style="color: rgb(10, 40, 150);">Мы приглашаем на работу специалистов Контактного центра в Барнауле, в Москве</span>
    </p>
`;

export const BLUE_OPTIONS_DESCRIPTION_HEADER_HTML = `
    <h1 class="ql-align-center">
        <span style="color: rgb(10, 40, 150);">Сделай шаг навстречу мечте!</span>
    </h1>
`;

export default {
    header: BLUE_HEADER_HTML,
    description: {
        header: BLUE_DESCRIPTION_HEADER_HTML,
        text: BLUE_DESCRIPTION_HTML,
        logo: BlueLogo
    },
    options1: {
        header: BLUE_OPTIONS_DESCRIPTION_HEADER_HTML,
        text: BLUE_OPTIONS_DESCRIPTION_HTML,
        options: [
            { html: BLUE_ABLITIES_HTML_1, image: Money },
            { html: BLUE_ABLITIES_HTML_2, image: Medicine },
            { html: BLUE_ABLITIES_HTML_3, image: Office }
        ]
    },
    content: {
        description: {
            header: BLUE_CONTENT_HEADER_2,
            text: BLUE_CONTENT_DESCRIPTION_1
        }
    },
    options2: {
        header: BLUE_OPTIONS_DESCRIPTION_HEADER_HTML,
        text: BLUE_OPTIONS_DESCRIPTION_HTML,
        options: [
            { html: BLUE_ABLITIES_HTML_1, image: Money },
            { html: BLUE_ABLITIES_HTML_2, image: Medicine },
            { html: BLUE_ABLITIES_HTML_3, image: Office }
        ]
    },
    options3: {
        header: BLUE_OPTIONS_DESCRIPTION_HEADER_HTML,
        text: BLUE_OPTIONS_DESCRIPTION_HTML,
        options: [
            { html: BLUE_ABLITIES_HTML_1, image: Money },
            { html: BLUE_ABLITIES_HTML_2, image: Medicine },
            { html: BLUE_ABLITIES_HTML_3, image: Office }
        ]
    },
    footer: {
        header: BLUE_FOOTER_TEXT_1,
        text: BLUE_FOOTER_TEXT_2
    }
};

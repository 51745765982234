import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { head } from 'ramda';

import EditContext from '../../context/EditContext';
import DataContext from '../../context/DataContext';

export default WrappedComponent =>
    class extends Component {
        static propTypes = {
            url: PropTypes.string,
            className: PropTypes.string
        };

        onChange = (e, onChange) => {
            const file = head(e.target.files);

            if (file) {
                const reader = new FileReader();

                reader.readAsDataURL(file);
                reader.onload = () => onChange(this.props.name, reader.result);
            }
        }

        render() {
            return <EditContext.Consumer>
                { edit => edit ?
                    <div className={`image-loader ${this.props.className || ''}`}>
                        <DataContext.Consumer>
                           { ({ onChange }) => <input type='file' accept='image/*' onChange={e => this.onChange(e, onChange)} />}
                        </DataContext.Consumer>
                        <WrappedComponent {...this.props} />
                    </div> : (
                        <div className='image-preview'>
                            <WrappedComponent {...this.props} />
                        </div>
                    )
                }
            </EditContext.Consumer>;
        }
    }

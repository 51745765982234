import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'ramda';

import Block from '../../Block';
import withSettings from '../../hocs/withSettings';

const StyledContainer = styled.div`
    background-color: ${prop('background')} !important;
`;

class Main extends Component {
    static propTypes = {
        settings: PropTypes.object,
        data: PropTypes.object
    };

    render() {
        const { settings, data } = this.props;

        return <StyledContainer className='block-1 bg-grey' {...settings}>
            <Block html={data.description} name='description' />
        </StyledContainer>;
    }
}

export default withSettings(Main, 'main');

import React, { Component } from 'react';
import AudioModal from './AudioModal';
import Loader from './Loader';
import { SoundOutlined } from '@ant-design/icons';

export default class AudioLoader extends Component {
    getPreview = () => {
        const { value } = this.props.input;

        return value ? <audio controls>
            <source src={value.base64}  />
        </audio> : '';
    }

    render() {
        return <Loader
            {...this.props}
            preview={this.getPreview()}
            accept='audio/*'
            capture='microphone'
            icon={SoundOutlined}
            Modal={AudioModal}
            recordLabel='Записать аудио'
            uploadLabel='Загрузить аудио'
            media={{ audio: true }} />;
    }
}

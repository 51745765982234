import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'ramda';

import Block from '../Block';
import withSettings from '../hocs/withSettings';
import StyledButton from './StyledButton';

const StyledContainer = styled.div`
    background-color: ${prop('background')};
`;

class Content extends Component {
    static propTypes = {
        settings: PropTypes.object,
        data: PropTypes.object,
        template: PropTypes.string,
        formUrl: PropTypes.string
    };

    render() {
        const { settings, template, data, formUrl } = this.props;

        return <StyledContainer className='block-2' {...settings}>
            <div className='wrap-block'>
                <Block html={data.content.header} name='content.header' className='content-header' />
                <Block html={data.content.text} allowLists name='content.text' />
                { template === 'green' &&
                    <div className='controls'>
                        <StyledButton settings={settings} formUrl={formUrl} />
                    </div>
                }
            </div>
        </StyledContainer>
    }
}

export default withSettings(Content, 'content');

import { take } from 'redux-saga/effects';

import isServer from '../utils/isServer';
import { taked } from '../utils/takeServerOnce';
import { getVacancy } from '../actions/asyncActions';

export default function* () {
    if (!isServer) {
        yield take(getVacancy.type);
        taked.splice(0, 1);
    }
}

import React from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';
import cx from 'classnames';

import DocumentHead from './DocumentHead';
import getLandingData from '../utils/getLandingData';
import { getVacancy } from '../actions/asyncActions';

const Success = ({ template }) => (
    <div className={cx('wrap success', { ancor: template === 'ancor' })}>
        <div className='success-block'>
            <span className='logo'></span>
            <h1>Анкета успешно отправлена!</h1>
            <DocumentHead title='Анкета успешно отправлена!' />
        </div>
    </div>
);

const stateToProps = state => ({
    template: path(['template'], getLandingData(getVacancy.selectData(state)))
});

export default connect(stateToProps)(Success);

import axios from 'axios';
import qs from 'qs';
import { pathOr } from 'ramda';

import { BOTS } from '../constants/baseUrls';

export const getLinkHost = link => /^(?:\w+:\/\/)?([^/]+)(.*)$/.exec(link)[1];
export const getLinkProtocol = link => pathOr('//', [0], /^(.*?):\/\//.exec(link));

export const botLink = (type, username, vacancy, link) => {
    switch (type) {
        case 'telegram':
            return `https://t.me/${username}${vacancy ? `?start=${vacancy}` : ''}`;
        case 'viber':
            return `viber://pa?chatURI=${username}${vacancy ? `&context=vacancy/${vacancy}` : ''}`;
        case 'facebook':
            return `https://m.me/${username}${vacancy ? `?ref=${vacancy}` : ''}`;
        case 'web':
            return `${getLinkProtocol(link)}${getLinkHost(link)}/chat/${username}${vacancy ? `?vacancy=${vacancy}` : ''}`;
        default:
            return null;
    }
};

export const getBotType = link => {
    if (/t\.me/.test(link)) {
        return 'telegram';
    }

    if (/viber:\/\/pa/.test(link)) {
        return 'viber';
    }

    if (/m\.me/.test(link)) {
        return 'facebook';
    }

    if (/\/chat\//.test(link)) {
        return 'web';
    }

    return null;
};

const getBotName = (link = '') => {
    return pathOr('', [1], link.match(/chat\/([^?]+)?/));
};

export const getWidgetHost = link => {
    const [ host ] = link.split('/chat/');
    return host;
};

export const getWidgetVacancy = link => {
    const [, searchPath = '' ] = link.split('?');
    const { vacancy } = qs.parse(searchPath);
    return vacancy;
};

export const checkLink = (vacancyLink) => {
    return new Promise(function(resolve, reject) {
        const [ url, searchPath = '' ] = vacancyLink.split('/messenger-select?');
        const [ host = '' ] = (url && url.match(/(https?:\/\/.*)/ig)) || [];
        const { vacancy } = qs.parse(searchPath);

        if (vacancy && vacancy !== 'undefined') {
            axios.get(`${host}${BOTS}`, {
                baseURL: null,
                params: { filter: { vacancy: vacancy } },
            })
                .then(({ items }) => resolve(items.map(item => ({
                    ...item,
                    link: botLink(item.type, item.username, vacancy, vacancyLink),
                }))))
                .catch(reject);
        } else {
            const type = getBotType(vacancyLink);
            const username = getBotName(vacancyLink);

            if (type) {
                resolve([{
                    type,
                    username,
                    link: vacancyLink,
                }]);
            } else {
                reject();
            }
        }
    });
};

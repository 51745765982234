import React from 'react';
import { Switch, Route } from 'react-router-dom';

import VacancyRoute from './VacancyRoute';
import NoMatch from './NoMatch';

export default () => (
    <Switch>
        <Route path='/:form(form)?/:success(success)?' exact component={VacancyRoute} />
        <Route path='/:id([\w-]+)/:form(form)?/:success(success)?' exact component={VacancyRoute} />
        <Route path='/:id([\w-]+)/:form(form)?/:vacancyId([a-z0-9]+)?/:success(success)?' exact component={VacancyRoute} />
        <Route component={NoMatch} />
    </Switch>
);

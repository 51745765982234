import IcoEducation from '../../assets/img/ico-education.png';
import IcoStably from '../../assets/img/ico-stably.png';
import IcoWelcome from '../../assets/img/ico-welcome.png';

export const CONTENT_HTML = `
    <br />
    <h6>
        <strong style="color: #2C89DB;">Что нам нужно:</strong>
    </h6>
    <br />
    <ul>
        <li>
            <span>Опыт работы в автокредитовании на территории официального дилерского центра от 0,5 года;</span>
        </li>
        <li>
            <span>Законченное высшее образование по направлению: экономика, финансы, банковское дело, менеджмент является желательным;</span>
        </li>
        <li>
            <span>Желание развиваться в финансовой сфере;</span>
        </li>
        <li>
            <span>Навыки продаж кредитных продуктов различным категориям клиентов;</span>
        </li>
        <li>
            <span>Интерес к сфере автомобильного бизнеса;</span>
        </li>
        <li>
            <span>Умение находить общий язык с разными людьми, доброжелательность, клиентоориентированность.</span>
        </li>
    </ul>
    <br />
    <h6>
        <strong style="color: #2C89DB;">Что нужно будет делать:</strong>
    </h6>
    <br />
    <ul>
        <li>
            <span>Консультирование клиентов по вопросам автокредитования на территории одного из официальных дилерских центров;</span>
        </li>
        <li>
            <span>Прием заявок и их передача в центральный офис для последующей обработки и принятия решения;</span>
        </li>
        <li>
            <span>Информирование клиентов о принятом решении и последующее консультирование по процессу заключения кредитного договора;</span>
        </li>
        <li>
            <span>Подготовка и оформление кредитных сделок;</span>
        </li>
        <li>
            <span>Осуществление продаж дополнительных продуктов.</span>
        </li>
    </ul>
    <br />
    <h6>
        <strong style="color: #2C89DB;">У тебя будет:</strong>
    </h6>
    <br />
    <ul>
        <li>
            <span>Стабильная работа в крупном банке;</span>
        </li>
        <li>
            <span>Современное оборудованное рабочее место;</span>
        </li>
        <li>
            <span>Белая заработная плата;</span>
        </li>
        <li>
            <span>Доход от 65 000 RUR;</span>
        </li>
        <li>
            <span>Работа на территории официального дилера премиального сегмента автомобилей;</span>
        </li>
        <li>
            <span>Возможность профессионального и личностного роста.</span>
        </li>
    </ul>
`;

export const CONTENT_HEADER_HTML = `
    <h1 class="ql-align-center">
        <span style="color: #07355E;">Менеджера по работе с клиентами в Компании</span>
    </h1>
`;

export const HEADER_HTML = `
    <h1 class="ql-align-center">
        <span style="color: rgb(255, 255, 255);">Построй карьеру в крупной компании</span>
    </h1>
`;

export const OPTION_HTML_1 = `
    <p class="ql-align-center">Стабильная работа, социальные гарантии и уверенность в завтрашнем дне</p>
`;

export const OPTION_HTML_2 = `
    <p class="ql-align-center">Возможность развиваться, обучаться и повышать свой профессиональный уровень</p>
`;

export const OPTION_HTML_3 = `
    <p class="ql-align-center">Работая в нашей компании, вы никогда не останетесь без работы!</p>
`;

export const FOOTER_HTML = `
    <h6>
        <span style="color: rgb(255, 255, 255);">Остались вопросы?</span>
    </h6>
    <br />
    <p>
        <span style="color: rgb(255, 255, 255);">Звони, мы с радостью ответим: <br />+7 (999) 777-00-11</span>
    </p>
`;

export default {
    header: HEADER_HTML,
    options: [
        { html: OPTION_HTML_1, image: IcoEducation },
        { html: OPTION_HTML_2, image: IcoStably },
        { html: OPTION_HTML_3, image: IcoWelcome }
    ],
    content: {
        header: CONTENT_HEADER_HTML,
        text: CONTENT_HTML
    },
    footer: {
        header: FOOTER_HTML
    }
};

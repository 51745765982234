import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Checkbox } from 'antd';
import { find } from 'ramda';
import { withAsyncActions } from 'react-async-client';
import styled from 'styled-components';

import withFieldWrapper from '../hocs/withFieldWrapper';
import { getVacancy } from '../../actions/asyncActions';

const getOPD = ({ getVacancy: { data: { parts = [] } } }) => {
    const OPD = find((item) => item.type === 'opd', parts);
    return { __html: OPD ? OPD.data : '' };
};

const Container = styled.div`
    img {
        max-width: 100%;
    }
`;

class OPD extends Component {
    static propTypes = {
        getVacancy: PropTypes.object.isRequired
    }

    state = {
        open: false
    }

    onChange = e => {
        this.props.input.onChange(e.target.checked ? '1' : '');
    }

    openModal = () => this.setState({ open: true });

    closeModal = () => this.setState({ open: false });

    render() {
        const { value, name } = this.props.input;

        return (
            <div className="opd">
                <Checkbox
                    name={name}
                    className='checkbox'
                    onChange={this.onChange}
                    checked={!!value}
                    value={value}
                />
                <span>Я даю согласие на
                    <span className="opd-link" onClick={this.openModal}>обработку персональных данных</span>
                </span>
                <Modal
                    visible={this.state.open}
                    title='Согласие на обработку персональных данных'
                    footer={null}
                    zIndex={100}
                    width={900}
                    onCancel={this.closeModal}>
                    <Container className='opd-content' dangerouslySetInnerHTML={getOPD(this.props)} />
                </Modal>
            </div>
        );
    }
};

export default withFieldWrapper(withAsyncActions({ getVacancy })(OPD));

import { Component } from 'react';
import PropTypes from 'prop-types';

import { getWidgetHost, getWidgetVacancy } from '../../utils/bots';
import { getUtm } from '../../utils/utm';

export default class BotWidget extends Component {
    static propTypes = {
        bot: PropTypes.object,
    }

    componentDidMount() {
        if (document && !document.getElementById('widget-script')) {
            const { bot } = this.props;
            const script = document.createElement('script');
            const host = getWidgetHost(bot.link);
            const vacancy = getWidgetVacancy(bot.link);
            const utm = getUtm();

            script.src = `${host}/widget.js?hideButton=true&botname=${bot.username}&vacancy=${vacancy}&${utm}`;
            script.async = true;
            script.id = 'widget-script';

            document.body.appendChild(script);
        }
    }

    render() {
        return null;
    }
}

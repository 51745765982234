import React, { Component } from 'react';
import styled from 'styled-components';

import withSettings from '../../hocs/withSettings';
import Block from '../../Block';
import StyledContainer from '../../StyledContainer';

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 1020px;
    margin: 0 auto;
    padding: 110px 0 60px;
    @media(max-width: 1080px){
        padding: 110px 20px 60px;
    }
    @media(max-width: 48em){
        padding: 80px 20px 50px;
    }
    .lime-title {
        margin-bottom: 20px;
        color: rgb(138, 197, 65);
    }
`;

const DescriptionTextBlock = styled.div`
    color: #000;
    h1,h2,h3,h4,h5,h6{
        font-weight: 100;
    }
`;

class Description extends Component {
    render() {
        const { settings, data } = this.props;

        return <StyledContainer {...settings}>
            <Wrapper>
                <div>
                    <div className='lime-title'>
                        <Block html={data.description.header} name='description.header' />
                    </div>
                    <DescriptionTextBlock>
                        <Block html={data.description.text} name='description.text' />
                    </DescriptionTextBlock>
                </div>
            </Wrapper>
        </StyledContainer>
    }
}

export default withSettings(Description, 'description');

import React, { Component } from 'react';
import styled from 'styled-components';
import { prop, remove, append, path, trim } from 'ramda';
import { Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import withSettings from '../../hocs/withSettings';
import Block from '../../Block';
import StyledButton from '../StyledButton';
import DataContext from '../../../context/DataContext';
import { GRAY_VACANCY_HEADER_HTML, GRAY_VACANCY_TEXT_HTML } from '../../../constants/templates/gray';
import VacancyDescription from './VacancyDescription';

const StyledContainer = styled.div`
    background: ${prop('background')};
`;

const VacancyTitle = styled.div`
    * {
        color: ${prop('vacancyColor')};
    }
`;

const StyledViewButtonContainer = styled.button`
    background-color: ${prop('btnViewBackground')};
    color: ${prop('btnViewTextColor')};
    &:hover {
        background-color: ${prop('btnViewHoverBackground')};
        color: ${({ btnViewHoverTextColor, btnViewTextColor }) => btnViewHoverTextColor || btnViewTextColor};
    }
`;

const getVacancyString = html => trim(html.replace(/<[^]*?>/g, ''));

class Vacancies extends Component {
    state = {
        openedModal: null
    };

    addVacancy = onChange => {
        const vacancies = append({
            title: GRAY_VACANCY_HEADER_HTML,
            content: GRAY_VACANCY_TEXT_HTML
        }, this.props.data.vacancies.data);

        onChange('vacancies.data', vacancies);
    }

    removeVacancy = (onChange, index) => {
        const vacancies = remove(index, 1, this.props.data.vacancies.data);

        onChange('vacancies.data', vacancies);
    }

    openedModal = index => this.setState({ openedModal: index });

    closeModal = () => this.setState({ openedModal: null });

    renderVacancy = (vacancy, index) => {
        const { data, settings, formUrl, allSettings } = this.props;

        return <div key={`vacancy-${index}`} className='vacancy-item'>
            <div className='vacancy-item-header'>
                <VacancyTitle className='vacancy-item-title' {...settings}>
                    <Block html={vacancy.title} name={`vacancies.data.${index}.title`} allowColor={false} />
                </VacancyTitle>
                { data.vacancies.data.length > 1 &&
                    <DataContext.Consumer>
                        { ({ onChange }) =>
                            <button
                                className='btn-remove-vacancy'
                                onClick={() => this.removeVacancy(onChange, index)}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                        }
                    </DataContext.Consumer>
                }
            </div>
            <StyledViewButtonContainer className='btn btn-view' {...settings} onClick={() => this.openedModal(index)}>
                { settings.btnViewText }
            </StyledViewButtonContainer>
            <StyledButton
                className='vacancy-item-apply-btn'
                settings={settings}
                formUrl={formUrl}
                state={{ vacancy: path(['modal', 'vacancies', index], allSettings) }}
                search={`?vacancy=${getVacancyString(vacancy.title)}`}
            />
            <Modal
                className='vacancy-item-modal'
                visible={this.state.openedModal === index}
                title={getVacancyString(vacancy.title)}
                footer={null}
                zIndex={100}
                width='80%'
                onCancel={this.closeModal}>
                <VacancyDescription
                    settings={this.props.allSettings}
                    opened={this.props.opened}
                    onSettingsToggle={this.props.onSettingsToggle}
                    vacancy={vacancy}
                    index={index}
                    options={{ path: String(index), titleWrapper: title => `<h4>${title}</h4>` }} />
            </Modal>
        </div>;
    }

    render() {
        const { data, settings } = this.props;

        return <StyledContainer className='vacancies-block' {...settings}>
            <Block html={data.vacancies.header} allowLists name='vacancies.header' />
            <div className='vacancy-items'>
                { data.vacancies.data.map(this.renderVacancy) }
            </div>
            <DataContext.Consumer>
                { ({ onChange }) =>
                    <button className='btn btn-add-vacancy' onClick={() => this.addVacancy(onChange)}>
                        <FontAwesomeIcon icon={faPlus} /> Добавить вакансию
                    </button>
                }
            </DataContext.Consumer>
        </StyledContainer>;
    }
}

export default withSettings(Vacancies, 'vacancies');

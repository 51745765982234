import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAsyncActions } from 'react-async-client';

import { getDictionary } from '../../actions/asyncActions';

export default WrappedComponent => {
    class Load extends Component {
        static propTypes = {
            getDictionary: PropTypes.object.isRequired
        };

        render() {
            const { data, meta } = this.props.getDictionary;

            return <WrappedComponent {...this.props} options={data} loading={meta.pending} />;
        }
    }

    return withAsyncActions(props => ({
        getDictionary: getDictionary
            .withPayload(() => props.loadname)
            .withParams({ loadName: props.loadname })
            .withOptions({ autoFetch: !!props.loadname })
    }))(Load);
};

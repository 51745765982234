import React, { Component } from 'react';
import { VideoCameraOutlined } from '@ant-design/icons';

import WebcamModal from './WebcamModal';
import Loader from './Loader';

export default class VideoLoader extends Component {
    state = {
        file: null
    };

    onChange = file => this.setState({ file });

    getPreview = () => {
        const { file } = this.state;

        return file && <video width={200} controls>
            <source src={URL.createObjectURL(file)} />
        </video>;
    }

    render() {
        return <Loader
            {...this.props}
            preview={this.getPreview()}
            accept='video/*'
            capture='camcorder'
            icon={<VideoCameraOutlined />}
            Modal={WebcamModal}
            recordLabel='Записать видео'
            uploadLabel='Загрузить видео'
            media={{ video: true, audio: true }}
            onChange={this.onChange} />;
    }
}

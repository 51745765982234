import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select as AntdSelect } from 'antd';
import { Field } from 'react-final-form';

import { fieldConfig } from '../../constants/fieldConfig';
import { COMMON_STR } from '../../constants/fieldTypes';
import withFieldWrapper from '../hocs/withFieldWrapper';
import { getValidator }  from '../../utils/validation';
import { getOtherId } from '../../utils/fields';

class SelectComponent extends Component {
    static propTypes = {
        options: PropTypes.array,
        onChange: PropTypes.func,
        field: PropTypes.object,
        fieldOptions: PropTypes.array,
        other: PropTypes.bool,
        customFieldValue: PropTypes.string,
        required: PropTypes.bool,
        change: PropTypes.func,
    };

    static defaultProps = {
        options: [],
        other: false,
        customFieldValue: PropTypes.object,
    };

    isOtherSelected = value => {
        return value === getOtherId(this.getOptions());
    }

    onChange = value => {
        const { other, input, change, customFieldValue } = this.props;

        input.onChange(value);

        if (other && !this.isOtherSelected(value)) {
            change(customFieldValue, undefined);
        }
    }

    getOptions() {
        const { fieldOptions, loadname, options: dictionaryOptions } = this.props;
        const options = loadname ? dictionaryOptions : fieldOptions;

        return options.map(option => ({
            id: option.id,
            name: loadname ? option.name : option.value,
        }))
    }

    renderOptions() {
        return this.getOptions().map(option =>
            <AntdSelect.Option value={option.id} key={option.id}>
                { option.name }
            </AntdSelect.Option>
        );
    }

    render() {
        const { input: { value, name }, customFieldValue, other, required } = this.props;

        return <div>
            <AntdSelect
                notFoundContent="Ничего не найдено"
                className='form-control'
                onChange={this.onChange}
                allowClear={!!value}
                value={value}
                name={name}>
                { this.renderOptions() }
            </AntdSelect>

            { other && this.isOtherSelected(value) &&
                <Field
                    name={customFieldValue}
                    component={fieldConfig[COMMON_STR]}
                    validate={getValidator({ isRequired: required })} />
            }
        </div>;
    }
}

const Select = withFieldWrapper(SelectComponent);
export const OtherSelect = props => <Select other={true} {...props} />;

export default Select;

import styled, { css } from 'styled-components';
import { prop } from 'ramda';
import { Box, Flex } from '@rebass/grid';

import { getThumbnailedBackground } from '../../../utils/thumbnail';

const hover = css`
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.1);
    }
`;

export const Container = styled.div`
    width: 100%;
    word-break: break-word;
    display: flex;
    flex-wrap: wrap;
    max-width: 1020px;
    margin: 0 auto;
    align-items: center;
    position: relative;
`;

export const Button = styled.button`
    cursor: pointer;
    background-color: ${props => props.view ? props.btnViewBackground : props.btnBackground};
    color: ${props => props.view ? props.btnViewTextColor : props.btnTextColor};
    border: 0;
    padding: ${props => props.small ? '10px 20px' : '14px 30px'};
    font-size: ${props => props.small ? '16px' : '20px'};
    outline: none;
    text-transform: uppercase;
    transition-property: transform;
    transition-duration: 0.3s;
    &:hover{
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        transform: scale(1.03);
        background-color: ${props => props.view ? props.btnViewHoverBackground : props.btnHoverBackground};
    }
    @media(max-width: 48em){
        padding: ${props => props.small ? '8px 15px' : '10px 20px'};
        font-size: ${props => props.small ? '14px' : '16px'};
    }
    ${({ hideButton }) => hideButton && css`
        visibility: hidden;
    `}
`;

export const Cover = styled.div`
    color: #fff;
    width: 100%;
    height: 529px;
    display: flex;
    align-self: center;
    background-image: ${getThumbnailedBackground()};
    background-color: ${prop('background')};
    background-position: ${({ backgroundPosition }) => backgroundPosition || 'center'};
    background-size: cover;
    box-shadow:0 0 0 1000px rgba(2, 2, 2, 0.1) inset;
    @media(max-width: 992px){
        height: inherit;
        min-height: 529px;
        padding: 0 0 20px;
    }
    ${Button}{
        margin-top: 25px;
    }
    @media screen and (max-width: 767px) {
        background-image: ${getThumbnailedBackground(true)};
    }

    ${({ isMobile }) => {
        return isMobile ? css`@media screen and (orientation:landscape) {
            background-image: ${getThumbnailedBackground(true, true)};
        }` : ''
    }}
`;

export const CoverContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15px;
    & > div {
        width: 100%;
    }
`;

export const BoxButton = styled(Box)`
    align-self: center;
    text-align: right;
    @media(max-width: 992px){
        padding: 20px 0;
        text-align: center;
      }
`;

export const Footer = styled(Box)`
    color: #fff;
    background-color: ${prop('background')};
    @media(max-width: 1024px){
        padding-right: 20px;
        padding-left: 20px;
    }
    @media(max-width: 992px){
        .ql-editor,
        .html-block {
            text-align: center;
        }
        text-align: center;
    }
`;

export const Card = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: ${prop('vacancyBackground')};
    color: #fff;
    min-height: 300px;
    background-image: url(${prop('image')});
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-right: ${props => props.image ? '0' : `1px solid ${props.vacancyBorder}`};
    border-top: ${props => props.image ? '0' : `1px solid ${props.vacancyBorder}`};
    justify-content: center;
    &:last-child{
        border-right: 0;
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: ${props => props.image ? 'rgba(0,0,0,0.4)' : 'transparent'};
    }

    ${props => props.hover && hover}
`;

export const TitleCard = styled.div`
    margin-bottom: 15px;
    position: relative;
    .ql-editor *,
    .html-block * {
        color: ${prop('vacancyColor')};
    }
`;

export const ControlsCard = styled.div`
    text-align: center;
    position: relative;
    button{
        margin: 10px 0 0 10px;
    }
`;

export const VacancyButton = styled.button`
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border: none;
    height: 30px;
    width: 30px;
    cursor: pointer;
    border-radius: 50%;
    display: none;
    align-items: center;
    justify-content: center;
`;

export const RemoveSmallVacancyButton = styled(VacancyButton)`
    position: absolute;
    z-index: 1;
    top: -15px;
    right: 20px;
`;

export const AddVacancyButton = styled(VacancyButton)`
    position: absolute;
    bottom: -${({ block }) => block ? 15 : 30 }px;
    left: calc(50% - ${({ block, blockText }) => !block && blockText ? -15 : 15}px);
    z-index: 1;
`;

export const UploadImageWrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: none;
    button {
        display: block;
    }
    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        opacity: 0;
        cursor: pointer;
        font-size: 0;
        z-index: 1;
    }
`;

export const VacanciesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    background-color: ${prop('background')};
    position: relative;
    .vacancies-title {
        color: rgb(138, 197, 65);
        padding-top: 30px;
        max-width: 1020px;
        margin: 0 auto 20px;
        width: 100%;
        position: relative;
    }
    .vacancies-items {
        width: 100%;
    }
    &:hover {
        .btn-hidden-vacancy {
            display: flex;
        }
    }
`;

export const RemoveImageButton = styled(VacancyButton)`
    position: relative;
    &:before {
        content: '';
        width: 1px;
        height: 20px;
        position: absolute;
        top: 5px;
        transform: rotate(45deg);
        background: rgba(0,0,0,0.65);
        left: 14px;
    }
    &:after {
        content: '';
        width: 1px;
        height: 20px;
        position: absolute;
        top: 5px;
        transform: rotate(-45deg);
        background: rgba(0,0,0,0.65);
        right: 14px;
    }
`;

export const ListCard = styled.div`
    background-color: ${prop('vacancyBackground')};
    padding: 100px 0;
    width: 100%;
    @media(max-width: 992px){
        padding: 50px 0;
    }
`;

export const BorderCard = styled.div`
    padding: 20px;
    text-align: center;
    border: 2px solid ${prop('vacancyBorder')};
    color: #fff;
    min-height: 120px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    .ql-editor *,
    .html-block * {
        color: ${prop('vacancyColor')};
        font-size: 16px !important;
    }

    ${props => props.hover && hover}
`;

export const VacancyControlButtons = styled.div`
    position: absolute;
    top: 20px;
    right: 15px;
    display: flex;
    z-index: 1;
    & > div,
    & > button {
        margin-left: 5px;
    }
`;

export const VacancyBlock = styled(Flex)`
    position: relative;

    ${props => props.hover && hover}
`;

export const VacancyBlockControlButtons = styled.div`
    position: absolute;
    top: ${({ block }) => block ? '-15px' : 'auto'};
    right: ${({ block }) => block ? 15 : 2}px;
    bottom: ${({ block }) => block ? 'auto' : 0};
    z-index: 1;
    display: flex;
    & > div,
    & > button {
        margin-left: 5px;
    }
`;

export const SmallVacancyBlock = styled(Box)`
    position: relative;
`;

export const VacancyLinkButtonWrapper = styled.div`
    .settings-btn {
        position: static !important;
        color: rgba(0, 0, 0, 0.65);
    }

    ${ ({ small }) => small && css`
        position: absolute;
        top: -15px;
        z-index: 1;
        right: -15px;
    `}

    ${ ({ noVacancies }) => noVacancies && css`
        display: none;
    `}
`;

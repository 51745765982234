import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { SagaProvider } from 'react-async-client';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import styled from 'styled-components';

import { pushRollbarError } from '../utils/rollbarInit';
import MainRoute from './MainRoute';
import ScrollToTop from './ScrollToTop';
import UrlContext from './UrlContext';
import IsMobileContext from './contexts/IsMobileContext';

const Error = styled.div`
    display: flex;
    align-items; center;
    justify-content: center;
    height: 100%;
    padding-top: 100px;
    cursor: poiner;
`;

export default class App extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    state = {
        error: false
    };

    componentDidCatch(error, info) {
        this.setState({ error: true });
        pushRollbarError(error, info);
    }

    render() {
        const { store, history, sagaMiddleware, url, Router: ServerRouter, isMobile } = this.props;
        const AppRouter = ServerRouter || Router;

        return (
            <SagaProvider sagaMiddleware={sagaMiddleware}>
                <Provider store={store}>
                    <ConfigProvider locale={ruRU}>
                        <AppRouter history={history}>
                            <IsMobileContext.Provider value={isMobile}>
                                <UrlContext.Provider value={url}>
                                    { this.state.error ? (
                                        <Error>Не удалось отобразить данные</Error>
                                    ) : (
                                        <ScrollToTop>
                                            <MainRoute />
                                        </ScrollToTop>
                                    )}
                                </UrlContext.Provider>
                            </IsMobileContext.Provider>
                        </AppRouter>
                    </ConfigProvider>
                </Provider>
            </SagaProvider>
        );
    }
}

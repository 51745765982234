import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Favicon from 'react-favicon';
import { head, tail } from 'ramda';
import Helmet from 'react-helmet';

import Header from './landingParts/Header';
import Options from './landingParts/Options';
import Content from './landingParts/Content';
import Footer from './landingParts/Footer';
import RedContent from './landingParts/red/Content';
import RedMain from './landingParts/red/Main';
import BlueDescription from './landingParts/blue/Description';
import BlueContent from './landingParts/blue/Content';
import AncorHeader from './landingParts/ancor/Header';
import Vacancies from './landingParts/gray/Vacancies';
import GrayMain from './landingParts/gray/Main';
import LimeDescription from './landingParts/lime/Description';
import LimeVacancies from './landingParts/lime/Vacancies';
import LimeHeader from './landingParts/lime/Header';
import LimeOptions from './landingParts/lime/Options';
import LimeFooter from './landingParts/lime/Footer';
import IcebergContent from './landingParts/iceberg/Content';
import { IcebergOptions1, IcebergOptions2, IcebergOptions3 } from './landingParts/iceberg/Options';

import { StyledBuilderContainer } from './StyledContainer';

const BLOCKS = {
    default: [ Header, Options, Content, Footer ],
    red: [ Header, RedMain, RedContent ],
    green: [ Header, Options, Content ],
    blue: [ Header, BlueDescription, BlueContent, Footer ],
    ancor: [ AncorHeader, Content, Footer ],
    gray: [ Header, GrayMain, Vacancies, Footer ],
    lime: [ LimeHeader, LimeDescription, LimeOptions, LimeVacancies, LimeFooter ],
    iceberg: [ Header, BlueDescription, IcebergOptions1, IcebergContent, IcebergOptions2, IcebergOptions3, Footer ]
};

export default class BuilderComponent extends Component {
    static propTypes = {
        settings: PropTypes.object.isRequired,
        template: PropTypes.string,
        isLanding: PropTypes.bool,
    };

    getSettings = key => this.props.settings[key] || {};

    render() {
        const { template, isLanding } = this.props;
        const background = this.getSettings('header').mainBackground;
        const { favicon } = this.getSettings('header');
        const { bulletType, bulletColor } = this.getSettings('content');
        const blocks = BLOCKS[template];
        const Header = head(blocks);
        const { script } = this.getSettings('common');

        return <StyledBuilderContainer
            className={`wrap ${template}`}
            background={background}
            bulletType={bulletType}
            bulletColor={bulletColor}
            template={template}
            styles={this.getSettings('common').styles}>
            { script && !isLanding &&
                <Helmet>
                    <script>{ script }</script>
                </Helmet>
            }
            <Favicon url={favicon || (template === 'ancor' ? '/ancorFavicon.ico' : '/favicon.ico')} />
            <Header {...this.props} />
            <div className='content'>
                { tail(blocks).map((Component, index) => (
                    <Component key={`component-${index}`}  {...this.props} />
                ))}
            </div>
        </StyledBuilderContainer>;
    }
}

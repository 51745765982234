
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/jsdom';

import 'core-js/modules/es.array.map';
import 'core-js/modules/es.array.from';
import 'core-js/modules/es.string.includes';
import 'core-js/modules/es.array.includes';
import 'core-js/features/object/values';

require('element-polyfill');
require('es6-promise').polyfill();

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { assocPath, identical } from 'ramda';

import DataContext from '../../context/DataContext';

export default WrappedComponent =>
    class extends Component {
        static propTypes = {
            data: PropTypes.object,
            params: PropTypes.object,
            template: PropTypes.string,
            onDataChange: PropTypes.func
        };

        static defaultProps = {
            data: {}
        };

        onChange = (key, data) => {
            const path = key.split('.').map(k => identical(NaN, +k) ? k : +k);

            this.props.onDataChange(assocPath(path, data, this.props.data));
        }

        render() {
            return <DataContext.Provider value={{
                    onChange: this.onChange,
                    data: this.props.data,
                }}>
                <WrappedComponent {...this.props} />
            </DataContext.Provider>
        }
    }

import Toolbox from '../../assets/img/toolbox.svg';
import Rocket from '../../assets/img/rocket.svg';
import Iheart from '../../assets/img/iheart.svg';

export const GREEN_HEADER_HTML = `
    <h1 class="ql-align-center">
        <span style="color: #FFF;">ПОСТРОЙ КАРЬЕРУ В НАШЕЙ КОМПАНИИ</span>
    </h1>
`;

export const GREEN_OPTION_HTML_1 = `
    <p class="ql-align-center">
        <span style="color: #3d3d3d;">Стабильная работа, социальные гарантии и уверенность в завтрашнем дне</span>
    </p>
`;

export const GREEN_OPTION_HTML_2 = `
    <p class="ql-align-center">
        <span style="color: #3d3d3d;">Возможность развиваться, обучаться и повышать свой профессиональный уровень</span>
    </p>
`;

export const GREEN_OPTION_HTML_3 = `
    <p class="ql-align-center">
        <span style="color: #3d3d3d;">Корпоративные программы и льготы</span>
    </p>
`;

export const GREEN_CONTENT_HEADER_HTML = `
    <h3 class="ql-align-center">Мы ищем продавца-консультанта</h3>
`;

export const GREEN_CONTENT_HTML = `
    <br />
    <p>
        <strong>Наше предложение:</strong>
    </p>
    <ul>
        <li>
            <span>Работа в дружной команде;</span>
        </li>
        <li>
            <span>Личностное и профессиональное развитие;</span>
        </li>
        <li>
            <span>Бесплатное обучение с первого дня работы;</span>
        </li>
        <li>
            <span>Комфортные условия труда (спортзал, комната отдыха, кафе);</span>
        </li>
        <li>
            <span>Оплачиваемый полис добровольного медицинского страхования, питание, корпоративную одежду;</span>
        </li>
        <li>
            <span>График работы 5\\2 (скользящие выходные);</span>
        </li>
        <li>
            <span>Официальное трудоустройство по ТК РФ.</span>
        </li>
    </ul>
    <br />
    <br />
    <p>
        <strong>Наши преимущества:</strong>
    </p>
    <ul>
        <li>
            <span>Доступный ремонт и обустройство своего дома для каждого гостя нашей компании! Комфортная и дружелюбная атмосфера;</span>
        </li>
        <li>
            <span>Мы работаем  с людьми, для которых команда - это не просто слова;</span>
        </li>
        <li>
            <span>Те, кого выбираем мы – выбирают нас;</span>
        </li>
        <li>
            <span>Мы делаем все для наших сотрудников, чтобы каждый смог реализовать себя.</span>
        </li>
    </ul>
    <br />
    <br />
    <p>
        <strong>Наши гарантии:</strong>
    </p>
    <ul>
        <li>
            <span>Возможность построить карьеру в международной компании;</span>
        </li>
        <li>
            <span>Работа в команде профессионалов;</span>
        </li>
        <li>
            <span>Достойная заработная плата и 100% соблюдение законодательства.</span>
        </li>
    </ul>
`;

export default {
    header: GREEN_HEADER_HTML,
    options: [
        { html: GREEN_OPTION_HTML_1, image: Toolbox },
        { html: GREEN_OPTION_HTML_2, image: Rocket },
        { html: GREEN_OPTION_HTML_3, image: Iheart }
    ],
    content: {
        header: GREEN_CONTENT_HEADER_HTML,
        text: GREEN_CONTENT_HTML
    }
};

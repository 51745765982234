import React, { Component } from 'react';
import { Flex, Box } from '@rebass/grid';
import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import withSettings from '../../hocs/withSettings';
import withImageLoad from '../../hocs/withImageLoad';
import Block from '../../Block';
import StyledContainer from '../../StyledContainer';

const Image = withImageLoad(({ url }) =>
    <div className='icon-box' style={{ backgroundImage: url ? `url(${url})` : null }} />
);

const Wrapper = styled(Box)`
    background-color: ${prop('background')};
    color: #000;
`;

const OptionsFlex = styled(Flex)`
    .ql-align-center{
        font-weight: 100;
    }
    @media(max-width: 48em){
        .ql-editor .ql-align-center{
            padding: 10px 0 30px 80px;
        }
    }
`;

const OptionsFlexText = styled(Box)`
    @media(max-width: 48em){
        padding: 10px 0 30px 80px;
        text-align: left;
    }
    @media(min-width: 48em){
        text-align: center;
    }
    .ql-editor{
        text-align: inherit;
    }
`;

const DescriptionWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 1020px;
    margin: 0 auto;
    padding: 50px 0 0;
    @media(max-width: 1080px){
        padding: 50px 20px 0;
    }
    @media(max-width: 48em){
        padding: 20px 20px 0;
    }
    .iceberg-title {
        margin-bottom: 20px;
        color: rgb(10, 40, 150);
    }
`;

const DescriptionTextBlock = styled.div`
    color: #000;
`;

const ContentWrapper = styled.div`
    ${({ hide }) => hide && css`
        opacity: .2;
    `}
`;

class Options extends Component {
    render() {
        const { settings, data, elementKey, isBuilder } = this.props;

        return settings.hide && !isBuilder ?
            null :
            <ContentWrapper hide={settings.hide && isBuilder}>
                <StyledContainer {...settings}>
                    <DescriptionWrapper>
                        <div>
                            <div className='iceberg-title'>
                                <Block html={data[elementKey].header} name={`${elementKey}.header`} />
                            </div>
                            <DescriptionTextBlock>
                                <Block html={data[elementKey].text} name={`${elementKey}.text`} />
                            </DescriptionTextBlock>
                        </div>
                    </DescriptionWrapper>
                </StyledContainer>
                <Wrapper {...settings} px={15}>
                    <OptionsFlex flexWrap='wrap' py={50}>
                        { (data[elementKey].options || []).map((option, index) =>
                            <Box key={`description-option-${index}`} width={[1, 1, 1/settings.elements]} px={15}>
                                <Image url={option.image} name={`${elementKey}.options.${index}.image`} />
                                <OptionsFlexText>
                                    <Block html={option.html} name={`${elementKey}.options.${index}.html`} />
                                </OptionsFlexText>
                            </Box>
                        )}
                    </OptionsFlex>
                </Wrapper>
            </ContentWrapper>;
    }
}

export const IcebergOptions1 = withSettings(Options, 'options1');
export const IcebergOptions2 = withSettings(Options, 'options2');
export const IcebergOptions3 = withSettings(Options, 'options3');

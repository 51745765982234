import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'ramda';

import withSettings from '../../hocs/withSettings';
import withImageLoad from '../../hocs/withImageLoad';
import Block from '../../Block';

const Image = withImageLoad(({ url }) =>
    <div className='icon-box' style={{ backgroundImage: url ? `url(${url})` : null }} />
);

const StyledContainer = styled.div`
    background-color: ${prop('background')};
`;

const StyledDescriptionBlock = styled.div`
    background-image: url(${prop('backgroundImage')});
`;

class Content extends Component {
    static propTypes = {
        settings: PropTypes.object,
        data: PropTypes.object
    };

    render() {
        const { settings, data: { content }} = this.props;

        return <StyledContainer background={settings.background}>
            <div className='block-2'>
                <div className='content-header'>
                    <Block html={content.abilities.header} name='content.abilities.header' />
                </div>
                { content.abilities.options.map((row, i) =>
                    <div className='row' key={`ability-row-${i}`}>
                        { row.map((item, index) =>
                            <div className={`ic-${index + 1}`} key={`ability-item-${i}-${index}`}>
                                <Image url={item.image} name={`content.abilities.options.${i}.${index}.image`} />
                                <Block html={item.html} name={`content.abilities.options.${i}.${index}.html`} />
                            </div>
                        )}
                    </div>
                )}
            </div>
            <StyledDescriptionBlock className='block-3' {...settings}>
                <div className='row'>
                    <div className='col-1'>
                        <Block html={content.description.header} allowLists name='content.description.header' />
                    </div>
                    <div className='col-2'>
                        <Block html={content.description.text} name='content.description.text' />
                    </div>
                </div>
            </StyledDescriptionBlock>
            <div className='block-4'>
                <div className='content-header'>
                    <Block html={content.duties.header} name='content.duties.header' />
                </div>
                <div className='row'>
                    { content.duties.options.map((option, index) =>
                        <div key={`option-${index}`} className={`ic-${index + 1}`}>
                            <Image url={option.image} name={`content.duties.options.${index}.image`} />
                            <Block html={option.html} name={`content.duties.options.${index}.html`} />
                        </div>
                    )}
                </div>
                <div className='red'>
                    <Block html={content.duties.text} name='content.duties.text' />
                </div>
            </div>
        </StyledContainer>;
    }
}

export default withSettings(Content, 'content');

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { path } from 'ramda';
import Favicon from 'react-favicon';
import { withRouter } from 'react-router-dom';

import { getVacancy } from '../actions/asyncActions';
import getLandingData, { getOgImageUrl } from '../utils/getLandingData';
import { isServer } from '../utils/isServer';
import UrlContext from './UrlContext';

class DocumentHead extends Component {
    static propTypes = {
        template: PropTypes.string,
        title: PropTypes.string,
        favicon: PropTypes.string,
        match: PropTypes.object,
        ogTitle: PropTypes.string,
        ogImage: PropTypes.string,
    };

    renderHeader = url => {
        const { title, template, favicon, match: { params: { id } }, ogTitle, ogImage, ogDescription, script, allowCanonical } = this.props;
        let ogImageUrl = getOgImageUrl(ogImage, id);
        ogImageUrl = `${url.origin}${ogImageUrl}`;

        return <Fragment>
            <Helmet>
                <title>{ title }</title>
                <meta property='og:url' content={url.href} />
                { !!ogImage && <meta property='og:image' content={ogImageUrl} /> }
                { !!ogImage && <meta name='twitter:image' content={ogImageUrl} /> }
                { !!ogImage && <meta property='og:image:width' content='1200' /> }
                { !!ogImage && <meta property='og:image:height' content='630' /> }
                { !!ogImage && <meta property='og:type' content='website' /> }
                { !!ogImage && <meta name='twitter:card' content='summary_large_image' /> }
                { !!ogTitle && <meta property='og:title' content={ogTitle} /> }
                { !!ogTitle && <meta name='twitter:title' content={ogTitle} /> }
                <meta property='og:description' content={ogDescription || ''} />
                { allowCanonical && !isServer() && <link rel='canonical' href={window.location.href} /> }
            </Helmet>
            <Favicon url={favicon || (template === 'ancor' ? '/ancorFavicon.ico' : '/favicon.ico')} />
            { script &&
                <Helmet>
                    <script>{ script }</script>
                </Helmet>
            }
        </Fragment>;
    }

    render() {
        return <UrlContext.Consumer>
            { this.renderHeader }
        </UrlContext.Consumer>
    }
}

const stateToProps = (state, props) => {
    const landingData = getLandingData(getVacancy.selectData(state));

    return {
        template: path(['template'], landingData),
        favicon: path(['data', 'settings', 'header', 'favicon'], landingData),
        ogTitle: path(['data', 'settings', 'common', 'bannerTitle'], landingData),
        ogImage: path(['data', 'settings', 'common', 'ogImage'], landingData),
        ogDescription: path(['data', 'settings', 'common', 'bannerDescription'], landingData),
    };
};

export default connect(stateToProps)(withRouter(DocumentHead));

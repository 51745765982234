import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'ramda';

import Block from '../../Block';
import withSettings from '../../hocs/withSettings';
import StyledButton from '../StyledButton';

const StyledContainer = styled.div`
    background: ${prop('background')};
`;

class Content extends Component {
    static propTypes = {
        settings: PropTypes.object,
        data: PropTypes.object,
        formUrl: PropTypes.string
    };

    render() {
        const { settings, data, formUrl } = this.props;

        return <StyledContainer className='block-2' {...settings}>
            <Block html={data.content.text} allowLists name='content.text' />
            <Block html={data.content.beforeButton} name='content.beforeButton' />
            <div className='controls'>
                <StyledButton settings={settings} formUrl={formUrl} />
            </div>
            <Block html={data.content.afterButton} name='content.afterButton' />
        </StyledContainer>;
    }
}

export default withSettings(Content, 'content');

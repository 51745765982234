import React from 'react';

import DocumentHead from './DocumentHead';

export default () => (
    <div className='wrap error'>
        <DocumentHead title='Страница не найдена' />
        <div className='error-block'>
            <h1>404</h1>
            <h2>Ой! Страница не найдена</h2>
        </div>
    </div>
);

import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createMemoryHistory, createBrowserHistory } from 'history';

import isServer from '../utils/isServer';
import rootReducer from '../reducers';
import rootSaga from '../sagas';

const configureStore = (initialState = {}, path = '/') => {
    const history = isServer ?
        createMemoryHistory({ initialEntries: [path] }) :
        createBrowserHistory();
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [];

    !isServer && middlewares.push(sagaMiddleware);

    if (process.env.NODE_ENV === 'development') {
        const { createLogger } = require('redux-logger');
        middlewares.push(createLogger({ collapsed: true }));
    }

    const store = createStore(
        rootReducer,
        initialState,
        applyMiddleware(...middlewares)
    );

    if (!isServer) {
        sagaMiddleware.run(rootSaga);
    }

    return {
        history,
        store,
        sagaMiddleware
    };
}

export default configureStore;

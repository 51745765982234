import './utils/iepolyfill';

import React from 'react';
import { hydrate, render } from 'react-dom';
import isMobile from 'ismobilejs';
import '@experium/findy-form/lib/styles/index.css';

import App from './components/App';
import { provideInterceptors, setBaseUrl, setLanguage } from './utils/http';
import configureStore from './store/configureStore';

import rollbarInit from './utils/rollbarInit';

import 'antd/dist/antd.css';
import 'react-custom-scroll/dist/customScroll.css';
import './styles/index.sass';
import './constants/images';
import './constants/html';

require('dotenv').config();

const MOUNT_NODE = document.getElementById('root');

if (process.env.NODE_ENV === 'production') {
    rollbarInit({
        token: '8f47b71c320b4e5d907f062cd3eba1fc',
        version: process.env.REACT_APP_VERSION,
        environment: window.location.host
    });

    window._version = {
        version: process.env.REACT_APP_VERSION
    };
}

if (process.env.NODE_ENV === 'development') {
    window.fileUrl = process.env.REACT_APP_GET_FILE_URL;
}

const preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const { store, history, sagaMiddleware } = configureStore(preloadedState);

provideInterceptors();
setBaseUrl();
setLanguage();

const renderDom = MOUNT_NODE.hasChildNodes() ? hydrate : render;
renderDom(
    <App
        store={store}
        history={history}
        sagaMiddleware={sagaMiddleware}
        url={{ origin: window.location.origin, href: window.location.href }}
        isMobile={isMobile(window.navigator).any} />,
    MOUNT_NODE
);

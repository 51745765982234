import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { getThumbnailedBackground } from '../../utils/thumbnail';
import Block from '../Block';
import withSettings from '../hocs/withSettings';
import StyledButton from './StyledButton';
import BotButton from './BotButton';
import Logo from './lime/Logo';
import IsMobileContext from '../contexts/IsMobileContext';

const StyledHeader = styled.header`
    background-color: ${prop('background')};
    background-image: ${getThumbnailedBackground()};
    border-bottom: 3px solid ${prop('borderBottomColor')};
    background-position: ${prop('backgroundPosition')};

    @media screen and (max-width: 767px) {
        background-image: ${getThumbnailedBackground(true)};
    }

    ${({ isMobile }) => {
        return isMobile ? css`@media screen and (orientation:landscape) {
            background-image: ${getThumbnailedBackground(true, true)};
        }` : ''
    }}
`;

const ButtonContainer = styled.div`
    visibility: ${({ show }) => show ? 'visible' : 'hidden'};
`;

class Header extends Component {
    static propTypes = {
        settings: PropTypes.object,
        template: PropTypes.string,
        data: PropTypes.object,
        formUrl: PropTypes.string,
        hideButton: PropTypes.bool,
        commonSettings: PropTypes.object
    };

    static defaultProps = {
        hideButton: false
    };

    render() {
        const { settings, template, data, formUrl, hideButton, commonSettings } = this.props;

        return <IsMobileContext.Consumer>
            { isMobile => <StyledHeader {...settings} isMobile={isMobile}>
                <div className='header-content'>
                    { settings.logo && <Logo logo={settings.logo} position={settings.logoPosition} /> }
                    <Block html={data.header} name='header' />
                    { template !== 'blue' &&
                        <ButtonContainer show={!hideButton} className='btn-main-container'>
                            <StyledButton className='btn-main' settings={settings} formUrl={formUrl} />
                        </ButtonContainer>
                    }
                    <BotButton settings={commonSettings} />
                </div>
            </StyledHeader>
            }
        </IsMobileContext.Consumer>;
    }
}

export default withSettings(Header, 'header');

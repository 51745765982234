import { faFacebookMessenger, faViber, faTelegramPlane } from '@fortawesome/free-brands-svg-icons';

export const BOT_ICONS = {
    viber: {
        icon: faViber, color: '#8f5db7'
    },
    facebook: {
        icon: faFacebookMessenger, color: '#0084ff'
    },
    telegram: {
        icon: faTelegramPlane, color: '#0088cc'
    }
};

import React, { Component, Fragment } from 'react';
import isMobile from 'ismobilejs'
import { Button, Spin } from 'antd';
import { head, path } from 'ramda';
import getUserMedia from 'getusermedia';
import { asyncConnect } from 'react-async-client';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';

import { postFile } from '../../../actions/asyncActions';
import { getFileUrl, getFileName } from '../../../helpers/fileHelpers';
import { PreviewWrapper, UploadButton } from './StyledComponents';

class Loader extends Component {
    state = {
        visibleModal: false,
        available: false
    };

    onSave = (value, file) => {
        this.onChange(value, file);
        this.stopStream();
        this.closeModal();
    }

    onLoad = e => {
        const file = head(e.target.files);

        if (file) {
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = () => {
                this.onChange(reader.result, file);
                this.closeModal();
            };
        }
    }

    stopStream = () => {
        this.stream.getTracks().forEach(track => track.stop());
    }

    remove = (index) => {
        const { options: { isMultiple }, fields } = this.props;

        if (isMultiple) {
            fields.remove(index);
        } else {
            this.onChange(null)
        }
    } ;

    onChange = (value, file) => {
        const { input, onChange, postFile } = this.props;
        const name = file ? file.name : getFileName(value);

        input.onChange(value ? {
            name,
            base64: value,
        } : null);
        onChange && onChange(file);

        if (value) {
            var formData = new FormData();
            formData.append('file', file || value);
            formData.append('name', name);
            postFile.dispatch(formData, { name });
        } else {
            postFile.reset();
        }
    }

    openModal = () => {
        if (!this.state.available) {
            getUserMedia(this.props.media, (err, stream) => {
                if (err) {
                    this.setState({ visibleModal: true });
                } else {
                    this.stream = stream;
                    this.setState({
                        visibleModal: true,
                        available: true
                    });
                }
            });
        } else {
            this.setState({ visibleModal: true });
        }
    }

    closeModal = () => this.setState({ visibleModal: false });

    getUploadButton = (hideLabel) => {
        const { accept, uploadLabel } = this.props;

        return <UploadButton>
            <UploadOutlined />
            { hideLabel ? null : ` ${uploadLabel}` }
            <input
                type='file'
                accept={accept}
                onChange={this.onLoad} />
        </UploadButton>;
    }

    render() {
        const { postFile, input: { value }, preview, accept, capture, Modal, recordLabel, icon: Icon, fileIndex } = this.props;
        const mobile = isMobile(window.navigator).any;

        return <Fragment>
            { value ?
                <PreviewWrapper>
                    { postFile.meta.pending && <Spin />}
                    { postFile.meta.error ? 'Ошибка' : preview }
                    <Button
                        shape='circle'
                        icon={<DeleteOutlined />}
                        type='danger'
                        onClick={() => this.remove(fileIndex)} />
                </PreviewWrapper> :
                Modal ? (
                    <Fragment>
                        <Button.Group>
                            <UploadButton onClick={!mobile && this.openModal}>
                                <Icon /> { recordLabel }
                                { mobile &&
                                    <input
                                        type='file'
                                        accept={accept}
                                        capture={capture}
                                        onChange={this.onLoad} />
                                }
                            </UploadButton>
                            {this.getUploadButton(true)}
                        </Button.Group>
                        { !mobile &&
                            <Modal
                                width={688}
                                visible={this.state.visibleModal}
                                closeModal={this.closeModal}
                                available={this.state.available}
                                onSave={this.onSave}
                                uploadButton={this.getUploadButton()}
                            />
                        }
                    </Fragment>
                ) : (
                    this.getUploadButton()
                )
            }
        </Fragment>;
    }
}

export default asyncConnect({
    postFile: postFile
        .withParams(props => ({ vacancyId: props.vacancyId, userId: path(['input', 'name'], props).replace('#', '') }))
        .withSuccessHandler(({ postFile: { data }, input , options: { isMultiple }, fields }, action) => {
            const requestValueName = path(['requestAction', 'attrs', 'name'], action);

            if (input.value && (requestValueName === input.value.name)) {
                input.onChange({
                    name: data.filename,
                    base64: input.value.base64,
                    requestValue: getFileUrl(data.id),
                });
                isMultiple && fields.push();
            }
        })
        .withErrorHandler(({ input, options: { isMultiple }, fields }) => {
            isMultiple ? fields.push() : input.onChange({
                requestValue: null,
            });
        })
})(Loader);


import uniqid from 'uniqid';
import { split, last } from 'ramda';

export const getFileUrl = id => `${window.location.origin}/api/file/${id}`

export const getFileName = (blob) => {
    if (!blob) {
        return uniqid();
    }

    const fileExtension = last(split('/', blob.type));

    return `${uniqid()}.${fileExtension}`;
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});

    return blob;
};

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'ramda';

import withSettings from '../../hocs/withSettings';
import withImageLoad from '../../hocs/withImageLoad';
import StyledButton from '../StyledButton';
import Block from '../../Block';

const Logo = withImageLoad(({ url }) => url ? (
    <Fragment>
        <span className='logo-image-helper' />
        <img className='logo-image' src={url} alt='Логотип' />
    </Fragment>
) : null);

const StyledContainer = styled.div`
    background-color: ${prop('background')} !important;
`;

class Description extends Component {
    static propTypes = {
        settings: PropTypes.object,
        data: PropTypes.object,
        formUrl: PropTypes.string
    };

    render() {
        const { settings, data: { description }, formUrl } = this.props;

        return <StyledContainer className='block-1' background={settings.background}>
            <Logo className='logo' url={description.logo} name={`description.logo`} />
            <div className='row'>
                <div className='col-1'>
                    <Block html={description.text} name='description.text' />
                </div>
                <div className='col-2'>
                    <Block html={description.header} name='description.header' />
                    <StyledButton settings={settings} formUrl={formUrl} />
                </div>
            </div>
        </StyledContainer>;
    }
}

export default withSettings(Description, 'description');

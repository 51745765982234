import React, { Component } from 'react';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components';
import { prop } from 'ramda';

import withSettings from '../../hocs/withSettings';
import { Container } from './styledComponents';
import withImageLoad from '../../hocs/withImageLoad';
import Block from '../../Block';

const Image = withImageLoad(({ url }) =>
    <div className='icon-box' style={{ backgroundImage: url ? `url(${url})` : null }} />
);

const Wrapper = styled(Box)`
    background-color: ${prop('background')};
    color: #000;
`;

const OptionsFlex = styled(Flex)`
    .ql-align-center{
        font-weight: 100;
    }
    @media(max-width: 48em){
        .ql-editor .ql-align-center{
            padding: 10px 0 30px 80px;
        }
    }
`;

const OptionsFlexText = styled(Box)`
    @media(max-width: 48em){
        padding: 10px 0 30px 80px;
        text-align: left;
    }
    @media(min-width: 48em){
        text-align: center;
    }
    .ql-editor{
        text-align: inherit;
    }
`;

class Options extends Component {
    render() {
        const { settings, data } = this.props;

        return <Wrapper {...settings} px={15}>
            <Container>
                <OptionsFlex flexWrap='wrap' py={50}>
                    { data.options.map((option, index) =>
                        <Box key={`description-option-${index}`} width={[1, 1, 1/3]} px={15}>
                            <Image url={option.image} name={`options.${index}.image`} />
                            <OptionsFlexText>
                                <Block html={option.html} name={`options.${index}.html`} />
                            </OptionsFlexText>
                        </Box>
                    )}
                </OptionsFlex>
            </Container>
        </Wrapper>;
    }
}

export default withSettings(Options, 'options');

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Alert } from 'antd';
import { last, split } from 'ramda';

import { b64toBlob } from '../../../helpers/fileHelpers';
import Audio from './Audio';
import { ErrorContentWrapper } from './StyledComponents';

export default class AudioModal extends Component {
    static propTypes = {
        onSave: PropTypes.func,
        closeModal: PropTypes.func,
        visible: PropTypes.bool,
        available: PropTypes.bool,
        uploadButton: PropTypes.node
    };

    onSave = data => {
        const base64 = last(split(',', data));
        const blobImage = b64toBlob(base64, 'audio/webm');
        this.props.onSave(data, blobImage);
    }

    render() {
        const { visible, closeModal, available, uploadButton } = this.props;

        return <Modal
            visible={visible}
            onCancel={closeModal}
            title='Аудио'
            footer={null}
            destroyOnClose>
            { available ?
                <Audio onSave={this.onSave} /> :
                    <ErrorContentWrapper>
                        <Alert
                            type='error'
                            message='Доступ к микрофону заблокирован. Разрешите доступ к микрофону в настройках браузера' />
                        { uploadButton }
                    </ErrorContentWrapper>
            }
        </Modal>;
    }
}

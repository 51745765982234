import React, { Component } from 'react';

import VacancyDescription from '../gray/VacancyDescription';

export default class LimeVacancyDescription extends Component {
    render() {
        const { index, row } = this.props;

        return <VacancyDescription {...this.props} name={`vacancies.data.${row}.${index}.content`} />;
    }
}

import Lifebuoy from '../../assets/img/lifebuoy.svg';
import Education from '../../assets/img/education.svg';
import Computer from '../../assets/img/computer.svg';

export const LIME_HEADER_HTML = `
    <h1 class="ql-align-center">
        <span style="color: #fff;">ПОСТРОЙ КАРЬЕРУ В КРУПНОЙ КОМПАНИИ</span>
    </h1>
`;

export const LIME_DESCRIPTION_HEADER_HTML = `
    <h3 class="ql-align-center">
        <strong style="color: #8ac541">О КОМПАНИИ</strong>
    </h3>
`;

export const LIME_DESCRIPTION_HTML = `
    <h4 class="ql-align-center">
        <span>Мы одни из лидеров финансового рынка России. Банк обслуживает физических лиц, индивидуальных предпринимателей, предприятия малого и среднего бизнеса. Миллионы клиентов ценят в нас надежность, передовые технологии и качественный сервис. Мы приглашаем на работу специалистов Контактного центра в Барнауле, в Москве</span>
    </h4>
`;

export const LIME_FOOTER_HTML = `
    <h3>
        <strong>Остались вопросы?</strong>
    </h3>
    <br />
    <h5>
        <span>Звони, мы с радостью ответим:</span>
    </h5>
    <br />
    <h4>
        <span style="color: #8ac541;">+7 (999) 777-00-11</span>
    </h4>
`;

export const LIME_OPTION_HTML = `
    <p class="ql-align-center">
        <span>Текст</span>
    </p>
`;

export const LIME_OPTION_HTML_1 = `
    <h5>
        <span>Стабильная работа, социальные гарантии и уверенность в завтрашнем дне</span>
    </h5>
`;

export const LIME_OPTION_HTML_2 = `
    <h5>
        <span>Возможность развиваться, обучаться и повышать свой профессиональный уровень</span>
    </h5>
`;

export const LIME_OPTION_HTML_3 = `
    <h5>
        <span>Работая в нашей компании, вы никогда не останетесь без работы!</span>
    </h5>
`;

export const LIME_VACANCIES_HEADER_HTML = `
    <h3 class="ql-align-center">
        <strong style="color: #8ac541">ВАКАНСИИ</strong>
    </h3>
`;

export const LIME_VACANCIES_DESCRIPTION_HTML = `
    <h4 class="ql-align-right">
        <span style="color: #fff">Прямо сейчас мы собираем отличную команду из специалистов разных направлений.</span>
    </h4>
    <br />
    <h5 class="ql-align-right">
        <span style="color: #fff">Просто выбери специальность, подходящую вакансию и заполни короткую анкету:</span>
    </h5>
`;

export const TURQUOUSE_VACANCY_HEADER_HTML_1 = `
    <h3 class="ql-align-center">
        <span>Входящие обращения</span>
    </h3>
`;

export const TURQUOUSE_VACANCY_HEADER_HTML_2 = `
    <h3 class="ql-align-center">
        <span>Управляющего супермаркетом</span>
    </h3>
`;

export const TURQUOUSE_VACANCY_HEADER_HTML_3 = `
    <h3 class="ql-align-center">
        <span>Продавец</span>
    </h3>
`;

export const TURQUOUSE_VACANCY_HEADER_HTML = `
    <h4 class="ql-align-center">Вакансия</h4>
`;

export const TURQUOUSE_VACANCY_TEXT_HTML = `
    <p>
        <span style="color: #8ac541">Требования:</span>
    </p>
    <ul>
        <li><span>Образование не ниже среднего</span></li>
        <li><span>Опыт работы в КЦ или Банке не менее года</span></li>
        <li><span>Опыт работы с IP телефонией</span></li>
        <li><span>Опыт работы с базами даных</span></li>
        <li><span>Уверенный пользователь Excel</span></li>
        <li><span>Быстрый набор текста, поиск информации в интернете, уверенное владение ПК</span></li>
        <li><span>Грамотная речь. Четкая дикция</span></li>
        <li><span>Базовые знания в области банковских продуктов и услуг будут являться преимуществом</span></li>
    </ul>
    <br />
    <p>
        <span style="color: #8ac541">Основные должностные обязанности:</span>
    </p>
    <ul>
        <li><span>Прием звонков на входящей линии в соответствии с KPIs</span></li>
        <li><span>Обработка заявок с сайта Банка и дистанционных обращений</span></li>
        <li><span>Продажа и доп. продажа продуктов Банка</span></li>
        <li><span>Участие в разработке скриптов</span></li>
        <li><span>Обучение новых сотрудников</span></li>
        <li><span>Подготовка отчетности</span></li>
        <li><span>Консультирование клиентов на входящей линии, решение вопроса клиента</span></li>
        <li><span>Исходящий обзвон с целью увеличения/удержания клиентской базы Банка</span></li>
        <li><span>Обзвон клиентов Банка в рамках маркетинговых компаний и заявок с сайта</span></li>
        <li><span>Исполнение доп.функционала и поручений Руководителя Группы</span></li>
    </ul>
    <br />
    <p>
        <span style="color: #8ac541">Условия:</span>
    </p>
    <ul>
        <li><span>Оформление в штат банка с первого дня</span></li>
        <li><span>Гибкий график 2/2 либо 5/2 с плавающим началом рабочего дня</span></li>
        <li><span>Достойный уровень заработной платы</span></li>
        <li><span>Возможность карьерного роста и развития в структуре банка</span></li>
    </ul>
`;

export const DEFAULT_LIME_VACANCY = {
    title: TURQUOUSE_VACANCY_HEADER_HTML,
    content: TURQUOUSE_VACANCY_TEXT_HTML
};

export default {
    header: LIME_HEADER_HTML,
    description: {
        header: LIME_DESCRIPTION_HEADER_HTML,
        text: LIME_DESCRIPTION_HTML
    },
    options: [
        { html: LIME_OPTION_HTML_1, image: Lifebuoy },
        { html: LIME_OPTION_HTML_2, image: Education },
        { html: LIME_OPTION_HTML_3, image: Computer }
    ],
    vacancies: {
        header: LIME_VACANCIES_HEADER_HTML,
        description: LIME_VACANCIES_DESCRIPTION_HTML,
        data: [[
            { title: TURQUOUSE_VACANCY_HEADER_HTML_1, content: TURQUOUSE_VACANCY_TEXT_HTML },
            { title: TURQUOUSE_VACANCY_HEADER_HTML_2, content: TURQUOUSE_VACANCY_TEXT_HTML },
            { title: TURQUOUSE_VACANCY_HEADER_HTML_3, content: TURQUOUSE_VACANCY_TEXT_HTML }
        ]]
    },
    footer: {
        header: LIME_FOOTER_HTML
    }
};

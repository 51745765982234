import React from 'react';
import { path, pathOr } from 'ramda';

import LandingBuilder from './LandingBuilder';
import getLandingData from '../utils/getLandingData';
import { FORM_LINK } from '../constants/urls';
import DocumentHead from './DocumentHead';

const Landing = ({ getVacancy, id }) => {
    const formUrl = FORM_LINK.stringify({ id });
    const data = getLandingData(getVacancy.data);

    return (
        <div className='landing'>
            <DocumentHead
                title={pathOr('Анкета кандидата', ['data', 'settings', 'common', 'pageTitle'], data)}
                script={path(['data', 'settings', 'common', 'script'] , data)}
                allowCanonical />
            { data && <LandingBuilder
                template={data.template}
                data={data.data.description}
                settings={data.data.settings}
                formUrl={formUrl}
                isLanding
            /> }
        </div>
    );
};

export default Landing;

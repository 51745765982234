import { forEachObjIndexed, isEmpty } from 'ramda';

import { BACKGROUND_IMAGE } from '../constants/urls';
import { getFullUrl } from '../utils/http';

export const getBackgroundColorStyle = (selector) => (value) => value ? `${selector} { background-color: ${value} !important; } ` : null;
const getColorStyle = (selector) => (value) => value ? `${selector} { color: ${value} !important; } ` : null;
const getBackgroundUrlStyle = (selector) => (id) => id < 0 ? '' :
    `${selector} { background-image: url(${getFullUrl(BACKGROUND_IMAGE, { id })}) !important; } `;
const getBorderColorStyle = (selector) => (value) => value ? `${selector} { border-color: ${ value } !important; } ` : null;

const getAntdAndButtonStyle = (selector) => (value) => {
    return value ? getBackgroundColorStyle(selector)(value) +
        getColorStyle('ul li:before')(value) +
        getBackgroundColorStyle('.ant-checkbox-checked .ant-checkbox-inner')(value) +
        getBorderColorStyle('.ant-checkbox-checked .ant-checkbox-inner')(value) +
        getBorderColorStyle('.ant-checkbox-wrapper:hover .ant-checkbox-inner')(value) +
        getBorderColorStyle('.ant-checkbox-input:focus + .ant-checkbox-inner')(value) +
        getBorderColorStyle('.ant-radio-inner')(value) +
        getBackgroundColorStyle('.ant-radio-inner:after')(value) +
        getBackgroundColorStyle('.ant-calendar-selected-day .ant-calendar-date')(value) +
        getColorStyle('.ant-calendar-today-btn')(value) : null;
};

const STYLING = {
    'successBackgroundColor': getBackgroundColorStyle('.success'),
    'successColor': getColorStyle('.success'),
    'backgroundColor': getBackgroundColorStyle('.wrap'),
    'blockColor': getBackgroundColorStyle('.body-block'),
    'footerColor': getBackgroundColorStyle('footer'),
    'teaserColor': getBackgroundColorStyle('.teaser'),
    'buttonColor': getAntdAndButtonStyle('.btn'),
    'fontColor': getColorStyle('.btn'),
    'imageDocId': getBackgroundUrlStyle('.bg'),
    'backgroundImageColor': getBackgroundColorStyle('.bg'),
};

export const getStyles = ({ data: { pageDescription }}) => {
    if (!pageDescription || isEmpty(pageDescription)) {
        return;
    }

    let styles = STYLING.successBackgroundColor(pageDescription.buttonColor)
        + STYLING.successColor(pageDescription.fontColor);
    forEachObjIndexed((value, key) => STYLING[key] ? styles += STYLING[key](value) : null, pageDescription);

    return styles;
};

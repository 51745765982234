import { createTaker } from 'react-async-client';
import { pathOr } from 'ramda';

import isServer from './isServer';

export const taked = !isServer && window.taked ? JSON.parse(window.taked) : [];

export const takeServerOnce = param => createTaker(
    action => `${action.type}_${pathOr('', ['payload', param], action)}`, !isServer, taked
);

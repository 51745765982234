import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import AudioLoader from './AudioLoader';
import VideoLoader from './VideoLoader';
import ImageLoader from './ImageLoader';
import DocumentLoader from './DocumentLoader';

const components = {
    document: DocumentLoader,
    audio: AudioLoader,
    video: VideoLoader,
    image: ImageLoader
}

class FileLoader extends Component {
    static propTypes = {
        options: PropTypes.object
    };

    static defaultProps = {
        options: {}
    };

    render() {
        const { fileType = 'document' } = this.props.options;

        const Loader = components[fileType];

        return <Loader {...this.props} />;
    }
}

export default withFieldWrapper(FileLoader);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import EditContext from '../../context/EditContext';

export default (WrappedComponent, key) =>
    class extends Component {
        static propTypes = {
            onSettingsToggle: PropTypes.func,
            opened: PropTypes.string,
            formUrl: PropTypes.string,
            settings: PropTypes.object
        };

        onToggle = () => this.props.onSettingsToggle(key, this.props.options);

        render() {
            const { settings } = this.props;

            return <div className={cx('settings', { opened: this.props.opened === key, 'settings-header': key === 'header' })}>
                <EditContext.Consumer>
                    { edit => edit && settings[key] ?
                        <button className={`settings-btn ${key === 'header' ? 'settings-btn-header' : ''}`} onClick={this.onToggle}>
                            <FontAwesomeIcon icon={faCog} />
                        </button> : null
                    }
                </EditContext.Consumer>
                <WrappedComponent
                    {...this.props}
                    elementKey={key}
                    settings={settings[key]}
                    commonSettings={settings.common || {}}
                    allSettings={settings} />
            </div>;
        }
    }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, InputNumber } from 'antd';

import withFieldWrapper from '../hocs/withFieldWrapper';

class InputComponent extends Component {
    static propTypes = {
        onChange: PropTypes.func
    };

    render() {
        const { input: { value, onChange, name }, type } = this.props;
        const InputComponent = type === 'number' ? InputNumber : Input;

        return (
            <InputComponent
                onChange={onChange}
                value={value}
                className='form-control'
                name={name}
            />
        );
    }
};

export default withFieldWrapper(InputComponent);

import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import withFieldWrapper from '../hocs/withFieldWrapper';

class ReCaptcha extends Component {
    render() {
        const { onChange } = this.props;

        return process.env.REACT_APP_RECAPTCHA ? <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA}
            onChange={onChange}
            style={{ marginBottom: 15 }}
            hl='ru'
        /> : null;
    }
}

export default withFieldWrapper(ReCaptcha);

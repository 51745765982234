import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import withSettings from '../../hocs/withSettings';
import Block from '../../Block';

const StyledContainer = styled.div`
    background-color: ${prop('background')};
    ${({ hide }) => hide && css`
        & > div {
            opacity: .2;
        }
    `}
`;

const StyledDescriptionBlock = styled.div`
    background-image: url(${prop('backgroundImage')});
`;

class Content extends Component {
    static propTypes = {
        settings: PropTypes.object,
        data: PropTypes.object
    };

    render() {
        const { settings, data: { content }, isBuilder } = this.props;

        return settings.hide && !isBuilder ?
            null :
            <StyledContainer background={settings.background} hide={settings.hide && isBuilder}>
                <StyledDescriptionBlock className='block-3' {...settings}>
                    <div className='row'>
                        <div className='col-1'>
                            <Block html={content.description.header} allowLists name='content.description.header' />
                        </div>
                        <div className='col-2'>
                            <Block html={content.description.text} name='content.description.text' />
                        </div>
                    </div>
                </StyledDescriptionBlock>
            </StyledContainer>;
    }
}

export default withSettings(Content, 'content');
